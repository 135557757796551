import { LocalizationProvider } from "@mui/x-date-pickers";
import { TickerDataStoreProvider } from "./TickerDataStore";
import TickerLanding from "./TickerLanding";
import { AddTickerModalProvider } from "./UseTickerCreator";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function TickerParent() {
  return (
    <TickerDataStoreProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AddTickerModalProvider>
          <TickerLanding />
        </AddTickerModalProvider>
      </LocalizationProvider>
    </TickerDataStoreProvider>
  );
}
