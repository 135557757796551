import Box from "@mui/material/Box";
import CartViewToolbar from "./CartViewToolbar";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import "./Repack.css";
import { RepackTicket, RepackTicketPrize } from "../Types/RepackTicket";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import {
  DataGridPremium,
  getGridNumericOperators,
  GridRowId,
} from "@mui/x-data-grid-premium";
import { useCallback, useState } from "react";
import { Typography } from "@mui/material";
import { RepackTicketType } from "../Types/RepackTicketType";

export default function TicketsTable() {
  const {
    selectedTicketIds,
    setSelectedTicketIds,
    availableTickets,
    allPrizes,
  } = useBuildRepacks();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);

  const handleRowExpandChange = useCallback((ids: GridRowId[]) => {
    setExpandedRowIds(ids);
  }, []);

  const getDetailPanelContent = useCallback(
    (params: { row: RepackTicket }) => {
      const ticket = params.row;

      if (!ticket) {
        console.error("No ticket found");
        return null;
      }

      const ticketPrizes = allPrizes.filter(
        (prize: RepackTicketPrize) => prize.repackTicketTypeId === ticket.repackTicketTypeId
      );
      
      return (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Ticket Details</Typography>
          <Box sx={{ mb: 2 }}>
            <Typography>Type: {ticket?.repackTicketType?.name || 'Unknown'}</Typography>
            <Typography>Cost: ${ticket?.cost || 0}</Typography>
          </Box>

          {ticketPrizes.length > 0 ? (
            <>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Potential Prizes
              </Typography>
              <DataGridPremium
                rows={ticketPrizes}
                getRowId={(row) => row.publicId || row.id.toString()}
                autoHeight
                hideFooter
                density="compact"
                columns={[
                  {
                    field: "name",
                    headerName: "Prize Name",
                    width: 200,
                    valueFormatter: (params: string) => params || 'N/A',
                  },
                  {
                    field: "cost",
                    headerName: "Prize Value",
                    width: 150,
                    valueFormatter: (params: number) => {
                      return params != null 
                        ? `${params.toFixed(2)}`
                        : 'N/A';
                    },
                  },
                  {
                    field: "isClaimed",
                    headerName: "Status",
                    width: 150,
                    valueGetter: (params: boolean) => {
                      return typeof params === 'boolean'
                        ? params ? "Claimed" : "Available"
                        : "Unknown";
                    },
                  },
                ]}
              />
            </>
          ) : (
            <Typography color="textSecondary">
              No prizes associated with this ticket yet
            </Typography>
          )}
        </Box>
      );
    },
    [allPrizes]  
  );

  const getRowId = useCallback((row: RepackTicket): GridRowId => {
    if (!row.id) {
      throw new Error("Row is missing publicId");
    }
    return row.id;
  }, []);

  return (
    <CollapsiblePaper
      title="Available Tickets"
      openTitle="View Available Tickets"
      closeTitle="Hide Available Tickets"
    >
      <Box sx={{ maxHeight: "50%", height: "100%", width: "100%" }}>
        <DataGridPremium
          loading={!availableTickets?.length}
          sx={{
            height: "400px",
          }}
          slots={{
            toolbar: CartViewToolbar,
          }}
          slotProps={{
            toolbar: { displayType: "tickets" },
          }}
          density="compact"
          rows={availableTickets}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 200}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
          getRowId={getRowId}
          columns={[
            {
              field: "repackTicketType",
              headerName: "Ticket Type",
              editable: false,
              width: 150,
              type: 'number',
              filterOperators: getGridNumericOperators(),
              valueGetter: (params: RepackTicketType) => {
                return params?.name || "Unknown";
              },
            },
            {
              field: "cost",
              headerName: "Cost",
              editable: false,
              width: 100,
              type: 'number',
              filterOperators: getGridNumericOperators(),
              valueFormatter: (params) => {
                return params != null
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    }).format(params)
                  : 'N/A';
              }
            },
          ]}
          pagination={false}
          disableColumnReorder
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            setSelectedTicketIds(ids as number[]);
          }}
          rowSelectionModel={selectedTicketIds}
        />
      </Box>
    </CollapsiblePaper>
  );
}