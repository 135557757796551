import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { RepackTicket } from "../Types/RepackTicket";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { RepackTicketType } from "../Types/RepackTicketType";

interface TicketSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (ticketId: string) => Promise<void>;
  availableTickets: RepackTicket[];
}

const TicketSelectionDialog: React.FC<TicketSelectionDialogProps> = ({
  open,
  onClose,
  onSelect,
  availableTickets,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Ticket</DialogTitle>
      <DialogContent>
        <DataGridPremium
          rows={availableTickets}
          getRowId={(row) => row.id}
          columns={[
            {
              field: "repackTicketType",
              headerName: "Type",
              width: 150,
              valueGetter: (params: RepackTicketType) =>
                params?.name || "Unknown",
            },
            {
              field: "cost",
              headerName: "Cost",
              width: 100,
              valueFormatter: (params: number) =>
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(params || 0),
            },
          ]}
          onRowClick={(params) => onSelect(params.row.publicId)}
          sx={{ minHeight: 400 }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TicketSelectionDialog;
