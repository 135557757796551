import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTickerDataStore } from "./TickerDataStore";

const TickerTabs: React.FC = () => {
  const { channels } = useTickerDataStore();
  const { selectedTab, setSelectedTab } = useTickerDataStore();
  return (
    <Stack 
      direction="row" 
      spacing={1} 
      sx={{ 
        overflowX: 'auto',
        flexWrap: 'nowrap',
        '&::-webkit-scrollbar': { height: 6 },
        '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
        '&::-webkit-scrollbar-thumb': { background: '#888' },
        pb: 1
      }}
    >
      <Chip
        key="all"
        label="All"
        onClick={() => setSelectedTab("all")}
        color={selectedTab === "all" ? "primary" : "default"}
        variant={selectedTab === "all" ? "filled" : "outlined"}
      />
      {channels.map((channel) => (
        <Chip
          key={channel.channelName}
          label={channel.channelName}
          onClick={() => setSelectedTab(channel.channelName)}
          color={selectedTab === channel.channelName ? "primary" : "default"}
          variant={selectedTab === channel.channelName ? "filled" : "outlined"}
        />
      ))}
    </Stack>
  );
}

export default TickerTabs;
