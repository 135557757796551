import BasicFilterInput from "./BasicFilterInput";
import FilterAutocomplete from "./FilterAutocomplete";


export class OperatorSetBuilder {
  private operatorSet: any[];

  constructor(){
    this.operatorSet = []
  }

  addBasicOperator(displayName: string, operator:string,){
    this.operatorSet.push({
      label: displayName,
      value: operator,
      InputComponent: BasicFilterInput,
    })
  }

  addAutocompleteSingleValue(operator: string, displayName:string,options:any[], labelPath:string,
    
  ){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        options: options,
        isMultiple:false,
        labelPath: labelPath,
      },
    })
  }

  addAutocompleteMultipleValue(operator: string, displayName:string,options:any[], labelPath:string, 
    
  ){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        options: options,
        isMultiple:true,
        labelPath: labelPath,
      },
    })
  }

  addNoValueFilter(operator: string, displayName:string){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: null
    })
  }

  build(){
    return this.operatorSet;
  }



}