import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import FetchModule from "../../../Modules/FetchModule";
import { RepackCase } from "../Types/RepackCaseType";

const StagedCasesPreview = () => {
  const [searchParams] = useSearchParams();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();
  const [caseBoxes, setCaseBoxes] = useState<RepackCase[]>([]);
  const [isLoadingBoxes, setIsLoadingBoxes] = useState(false);

  const fetchData = new FetchModule();
  //patch - ignore me

  const fetchCaseBoxes = async (caseId: string) => {
    setIsLoadingBoxes(true);
    try {
      const response = await fetchData.fetchResponseAuthed(
        `/Repack/get/case/${caseId}`,
        "GET"
      );
      const data = await response.json();
      setCaseBoxes(data.boxes || []);
    } catch (error) {
      console.error("Error fetching case boxes:", error);
      newSnackbarMessage("Error fetching case boxes.", "error");
    } finally {
      setIsLoadingBoxes(false);
    }
  };

  const {
    caseTypes,
    cases,
    selectedCase,
    setSelectedCase,
    createNewCase,
    updateCase,
    deleteCase,
    setSelectedSeriesId,
  } = useBuildRepacks();

  const [selectedCaseTypeId, setSelectedCaseTypeId] = useState<number | string>(
    caseTypes[0]?.publicId || 1
  );

  const handleSelectedCaseTypeChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedCaseTypeId(event.target.value);
  };

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds]);

  const handleCreateCase = async () => {
    if (!selectedCaseTypeId) {
      newSnackbarMessage("Please select a case type.", "error");
      return;
    }

    try {
      await createNewCase({
        repackSeriesId: seriesId,
        repackCaseTypeId: selectedCaseTypeId,
        isStaged: true,
      });
    } catch (error) {
      console.error("Failed to create case:", error);
      newSnackbarMessage("Error creating case.", "error");
    }
  };

  const handleDeleteCase = async () => {
    if (!selectedCase) {
      newSnackbarMessage("No case selected.", "error");
      return;
    }

    try {
      await deleteCase(selectedCase.publicId);
      setExpandedRowIds([]);
      setSelectedCase(null);
    } catch (error) {
      console.error("Failed to delete case:", error);
      newSnackbarMessage("Error deleting case.", "error");
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.id === selectedCase?.id) {
      setExpandedRowIds([]);
      setSelectedCase(null);
      return;
    }

    const c = cases.find((rc) => rc.publicId === params.id);
    if (!c) return;
    setSelectedCase(params.row);
    setExpandedRowIds([params.id]);
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    if (ids.length === 1) {
      const expandedId = ids[0];
      const c = cases.find((rc) => rc.publicId === expandedId);

      setExpandedRowIds([expandedId]);
      if (c) {
        setSelectedCase(c);
        fetchCaseBoxes(c.publicId);
      }
    } else {
      setExpandedRowIds([]);
      setSelectedCase(null);
      setCaseBoxes([]);
    }
  };

  const handleUpdateCase = async (updatedRow: any) => {
    try {
      const caseToUpdate = {
        ...selectedCase,
        serialNumber: updatedRow.serialNumber || "",
        publicId: updatedRow.publicId,
        repackSeriesId: Number(seriesId),
        isStaged: true,
        repackCaseTypeId: selectedCase?.repackCaseTypeId || 1,
        id: selectedCase?.id || 0,
        productId: selectedCase?.productId || "",
        boxes: selectedCase?.boxes || [],
        repackCaseType: selectedCase?.repackCaseType || { id: 1, publicId: "default", name: "Default" },
      };

      await updateCase(updatedRow.publicId, caseToUpdate);
      return updatedRow;
    } catch (error) {
      console.error("Failed to update case:", error);
      newSnackbarMessage("Error updating case.", "error");
      return null;
    }
  };

  const caseColumns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 100,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 150,
      editable: true,
    },
    {
      field: "repackCaseType",
      headerName: "Case Type",
      width: 150,
      valueGetter: (params) => params.row.repackCaseType?.name || "Unknown",
    },
    {
      field: "boxCount",
      headerName: "Box Count",
      width: 120,
      valueGetter: (params) => params.row.boxes?.length || 0,
    },
    {
      field: "comp",
      headerName: "Total Comp",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        return (
          params.row.boxes?.reduce((acc: number, box: any) => {
            const items = box.repackItems || [];
            const boxTotal = items.reduce((itemAcc: number, item: any) => {
              if (item.repackCard?.comp) {
                return itemAcc + Number(item.repackCard.comp);
              }
              if (item.repackTicket?.cost) {
                return itemAcc + Number(item.repackTicket.cost);
              }
              return itemAcc;
            }, 0);
            return acc + boxTotal;
          }, 0) || 0
        );
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        return (
          params.row.boxes?.reduce((acc: number, box: any) => {
            const items = box.repackItems || [];
            const boxTotal = items.reduce((itemAcc: number, item: any) => {
              if (item.repackCard?.totalCost) {
                return itemAcc + Number(item.repackCard.totalCost);
              }
              if (item.repackTicket?.cost) {
                return itemAcc + Number(item.repackTicket.cost);
              }
              return itemAcc;
            }, 0);
            return acc + boxTotal;
          }, 0) || 0
        );
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        const comp =
          params.row.boxes?.reduce((acc: number, box: any) => {
            const items = box.repackItems || [];
            return (
              acc +
              items.reduce((itemAcc: number, item: any) => {
                if (item.repackCard?.comp)
                  return itemAcc + Number(item.repackCard.comp);
                if (item.repackTicket?.cost)
                  return itemAcc + Number(item.repackTicket.cost);
                return itemAcc;
              }, 0)
            );
          }, 0) || 0;

        const cost =
          params.row.boxes?.reduce((acc: number, box: any) => {
            const items = box.repackItems || [];
            return (
              acc +
              items.reduce((itemAcc: number, item: any) => {
                if (item.repackCard?.totalCost)
                  return itemAcc + Number(item.repackCard.totalCost);
                if (item.repackTicket?.cost)
                  return itemAcc + Number(item.repackTicket.cost);
                return itemAcc;
              }, 0)
            );
          }, 0) || 0;

        return comp - cost;
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          const value = Number(params.value);
          return `$${value.toFixed(2)}`;
        }
        return "";
      },
      cellClassName: (params) => {
        const value = Number(params.value || 0);
        return value >= 0 ? "positive-value" : "negative-value";
      },
    },
    {
      field: "itemCount",
      headerName: "Total Items",
      width: 120,
      valueGetter: (params) =>
        params.row.boxes?.reduce(
          (acc: number, box: any) => acc + (box.repackItems?.length || 0),
          0
        ) || 0,
    },
    {
      field: "datesActive",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) => {
        const dateStr = params.row.datesActive?.lowerBound;
        return dateStr ? new Date(dateStr).toLocaleDateString() : "";
      },
    },
  ];

  const boxColumns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 100,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "repackBoxTypeId",
      headerName: "Box Type",
      width: 150,
      valueGetter: (params) => params.row.repackBoxType?.name || "Unknown",
    },
    {
      field: "datesActive",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) => {
        const dateStr = params.row.datesActive?.lowerBound;
        if (!dateStr) return "";
        const date = new Date(dateStr);
        return date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: any) => {
          if (item.repackCard?.comp) {
            return acc + Number(item.repackCard.comp);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: any) => {
          if (item.repackCard?.totalCost) {
            return acc + Number(item.repackCard.totalCost);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        const total = items.reduce((acc: number, item: any) => {
          if (item.repackCard) {
            const comp = Number(item.repackCard.comp || 0);
            const cost = Number(item.repackCard.totalCost || 0);
            return acc + (comp - cost);
          }
          return acc;
        }, 0);
        return total;
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          const value = Number(params.value);
          return `$${value.toFixed(2)}`;
        }
        return "";
      },
      cellClassName: (params) => {
        const value = Number(params.value || 0);
        return value >= 0 ? "positive-value" : "negative-value";
      },
    },
    {
      field: "itemCount",
      headerName: "Items",
      width: 100,
      valueGetter: (params) => params.row.repackItems?.length || 0,
    },
  ];

  const itemColumns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 100,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      valueGetter: (params) => (params.row.repackCard ? "Card" : "Ticket"),
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      valueGetter: (params) =>
        params.row.repackCard?.name || `Ticket #${params.row.repackTicket?.id}`,
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 150,
      valueGetter: (params) => {
        if (params.row.repackCard) return params.row.repackCard.comp;
        if (params.row.repackTicket) return params.row.repackTicket.cost;
        return 0;
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 150,
      valueGetter: (params) => {
        if (params.row.repackCard) return params.row.repackCard.totalCost;
        if (params.row.repackTicket) return params.row.repackTicket.cost;
        return 0;
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
  ];

  const getBoxDetailPanelContent = (params: any) => {
    const items = params.row.repackItems || [];

    return (
      <div style={{ padding: "20px" }}>
        <DataGridPro
          getRowId={(row) => row.publicId || row.id}
          rows={items}
          columns={itemColumns}
          autoHeight
          hideFooter
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        />
      </div>
    );
  };

  const getDetailPanelContent = () => {
    if (isLoadingBoxes) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress />
        </Grid>
      );
    }

    if (!caseBoxes.length) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="flex"
          flexDirection="column"
        >
          <Grid item padding={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50%"
            >
              <Typography variant="h6" color="textSecondary">
                There are no boxes in this case yet
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleDeleteCase}>
              <DeleteIcon />
              Delete Case
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid>
        <DataGridPro
          getRowId={(row) => row.publicId || row.id}
          rows={caseBoxes}
          columns={boxColumns}
          autoHeight
          hideFooter
          getDetailPanelContent={getBoxDetailPanelContent}
          getDetailPanelHeight={() => 400}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        />
        <Divider />
        <Button variant="text" onClick={handleDeleteCase}>
          <DeleteIcon />
          Delete Case
        </Button>
      </Grid>
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Select
            fullWidth
            value={selectedCaseTypeId}
            onChange={handleSelectedCaseTypeChange}
          >
            {caseTypes.map((type) => (
              <MenuItem key={type.id} value={type.publicId}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={handleCreateCase}>
            Add New Case
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  return (
    <CollapsiblePaper
      title="Staged Cases Preview"
      closeTitle="Close Preview"
      openTitle="Open Preview"
    >
      <div style={{ height: "auto", minHeight: "400px", width: "100%" }}>
        <DataGridPro
          getRowId={(row) => row.publicId || row.id}
          rows={cases}
          columns={caseColumns}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 250}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
          disableMultipleRowSelection={true}
          onRowClick={handleRowClick}
          processRowUpdate={handleUpdateCase}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing case update:", error);
            newSnackbarMessage("Error processing case update.", "error");
          }}
          components={{ Toolbar: CustomToolbar }}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        />
      </div>
    </CollapsiblePaper>
  );
};

export default StagedCasesPreview;
