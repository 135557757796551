import React from "react";
import { BuildRepacksProvider } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import RepackCreationContent from "./RepackCreationContent";
import { RepackCreatorCardPreviewProvider } from "../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { CardsProvider } from "../../../Contexts/CardsContext/CardsContext";

export default function RepackCreation(props: any) {
  return (
    <CardsProvider>
      <RepackCreatorCardPreviewProvider>
        <BuildRepacksProvider>
          <RepackCreationContent {...props} />
        </BuildRepacksProvider>
      </RepackCreatorCardPreviewProvider>
    </CardsProvider>
  );
}