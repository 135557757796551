import * as React from 'react';
import { createContext, MutableRefObject, useContext } from "react";
import { useDatagridDataConnection } from "../../Components/CommonUtility/ServerSide/useDatagridDataConnection" 
import { GridApiPro } from '@mui/x-data-grid-pro';
import { LocalStorageKey } from '../../Utilities/LocalStorage/LocalStorageEnum';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

type CardsProviderProps = {
  children?: React.ReactNode;
}

// eslint-disable-next-line
interface CardsContextProps {
  refreshData: any,
  rows: any,
  setRows: (value:any) => any,
  isGettingData: any,
  totalRows: number,
  goTo: (identifier: number | string) => Promise<void>,
  goToViaURLParams: any,
  filterModel: any, 
  setFilterModel: (value:any) => any, 
  getPreprocessedFilterModel: () => any,
  updateFilterModel: (field:string,operator:string,value:any) => void,
  paginationModel: any, setCurrentPage: (page: number) => any,
  setPageSize: (pageSize: number) => any, lastEditWasManual: any, setLastEditWasManual: (value: boolean) => any,
  sortModel: any, setSortModel: (value:any) => any, resetSortModel: () => any,
  setJumpToRef: any,
  gridApi:MutableRefObject<GridApiPro | GridApiPremium>
}
const CardsContext = createContext<CardsContextProps | null>(null);

export const CardsProvider = ({children} : CardsProviderProps) => {
    const baseurl="/Card/all/";
    const goToURL="/Card/getlastcardpage"
    const filterKey=LocalStorageKey.CARD_DASHBOARD_FILTERS
    const sortKey=LocalStorageKey.CARD_DASHBOARD_SORT
    const automaticallyRefreshData=true
    const isPaginated=true

    const {    
        refreshData,
        rows,
        setRows,
        isGettingData,
        totalRows,
        goTo,
        goToViaURLParams,
        filterModel,
        setFilterModel,
        updateFilterModel,
        getPreprocessedFilterModel,
        paginationModel,setCurrentPage,setPageSize, 
        lastEditWasManual,setLastEditWasManual,
        sortModel, setSortModel, resetSortModel,
        setJumpToRef,gridApi
    } = useDatagridDataConnection({
            filterKey,
            sortKey,
            automaticallyRefreshData,
            isPaginated,
            baseurl,
            goToURL,
    })

    const contextValues = {
        refreshData,
        rows,
        setRows,
        isGettingData,
        totalRows,
        goTo,
        goToViaURLParams,
        filterModel,
        setFilterModel,
        updateFilterModel,
        getPreprocessedFilterModel,
        paginationModel,setCurrentPage,
        setPageSize: (pageSize: number) => setPageSize(pageSize),
        lastEditWasManual: lastEditWasManual,
        sortModel, setSortModel, resetSortModel,
        setJumpToRef,
        setLastEditWasManual,
        gridApi
    }

    return (
        <CardsContext.Provider value={contextValues}>
          {children}
        </CardsContext.Provider>
    )
}

export function useCards() {
  const context = useContext(CardsContext);
  if (!context) {
      throw new Error("Invalid CardsContext, must be wrapped in CardsProvider")
  }
  return context;
}


