import { Autocomplete, Box, Button, Grid, MenuItem, Modal, Select, TextField } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import FetchModule from "../../Modules/FetchModule";
import { useUser } from "../../Components/CommonUtility/UserContext";
import { useTickerDataStore } from "./TickerDataStore";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Dayjs } from "dayjs";
import { useFeatureFlags } from "../../Contexts/FeatureFlagContexts/FeatureFlagContext";
import { useSnackbar } from "../../Contexts/SnackbarContext/SnackbarContext";
import { ChannelInfo } from "../../Types/DataModels/ShowChannel/TickerChannelInfo";


type TickerCreatorContextProps = {
  children: React.ReactNode;

}
type TickerCreatorProviderProps = {
  isTickerCreatorOpen: boolean;
  setIsTickerCreatorOpen: (value: boolean) => void;
}

const AddTickerModalContext = createContext<TickerCreatorProviderProps | null>(null);
const fetchData = new FetchModule();
export const AddTickerModalProvider = ( {children}:TickerCreatorContextProps ) => {
  const [text, setText] = useState('');
  const [selectedChannels, setSelectedChannels] = useState<ChannelInfo[]>([]);
  const {channels} = useTickerDataStore();
  const {user} = useUser();
  const [isTickerCreatorOpen, setIsTickerCreatorOpen] = useState(false);
  const [startType, setStartType] = useState<string>("start");
  const [scheduleStartTime, setScheduleStartTime] = useState<Dayjs | null>(null);
  const [scheduleEndTime, setScheduleEndTime] = useState<Dayjs | null>(null);
  const {flags} = useFeatureFlags();
  const {newSnackbarMessage} = useSnackbar();
  const [lastClickTime, setLastClickTime] = useState<number>(0);
  const [lastSubmittedValues, setLastSubmittedValues] = useState<{
    text: string;
    startType: string;
    scheduleStartTime: Dayjs | null;
    scheduleEndTime: Dayjs | null;
    channels: ChannelInfo[];
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submit = async () => {
    setIsSubmitting(true);
    setLastClickTime(Date.now());
    setLastSubmittedValues({
      text,
      startType,
      scheduleStartTime,
      scheduleEndTime,
      channels: selectedChannels
    });
    const channelGuids =  selectedChannels.map((channel) => channel.publicId)
    const response = await fetchData.promise("/Ticker/create", true, "POST", {
      UserId: user["id"],
      text: text,
      channels: channelGuids,
      startType: startType,
      scheduleStartTime: scheduleStartTime,
      scheduleEndTime: scheduleEndTime,
    });
    if(response.status === 200){
      setIsTickerCreatorOpen(false);
    }
    else{
      newSnackbarMessage("Failed to create ticker", "error");
    }
    setIsSubmitting(false);
  }
  useEffect(() => {
    if (lastClickTime === 0) return;
    
    const timeoutId = setTimeout(() => {
      setLastClickTime(prev => prev);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [lastClickTime]);
  const isButtonDisabled = () => {
    const hasBasicValidationErrors = 
      text.length === 0 || 
      (startType === "schedule" && (!scheduleStartTime || !scheduleEndTime)) ||
      !!(scheduleEndTime && scheduleStartTime && scheduleEndTime.isBefore(scheduleStartTime));
  
    const isInCooldownWithNoChanges = 
      Date.now() - lastClickTime < 2000 &&
      lastSubmittedValues?.text === text &&
      lastSubmittedValues?.startType === startType &&
      lastSubmittedValues?.channels?.every(channel => selectedChannels.some(c => c.publicId === channel.publicId))
   
    
      return hasBasicValidationErrors || isInCooldownWithNoChanges || isSubmitting;
  };

  return (
    <AddTickerModalContext.Provider value={{isTickerCreatorOpen,setIsTickerCreatorOpen}}>
      {children}
      <Modal open={isTickerCreatorOpen}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: 400,
      }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Box>Ticker Text:</Box>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              label="Ticker Text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={3}>
            <Box>Channels:</Box>
          </Grid>
          <Grid item xs={9}>
          <Autocomplete
            getOptionLabel={(option) => option.channelName}
            renderInput={(params) => <TextField {...params} label="Channel" />}
            multiple
            onChange={(_, value: ChannelInfo[]) => {
              if (value.find(v => v.channelName === "All")) {
                setSelectedChannels(selectedChannels.length === channels.length ? [] : channels);
              } else {
                setSelectedChannels(value as ChannelInfo[]);
              }
            }}
            value={selectedChannels}
            options={[{ channelName: "All", publicId: "all", id:-1 }, ...channels]}
          />
          </Grid>

          <Grid item xs={3}>
            <Box>Start Type:</Box>
          </Grid>
          <Grid item xs={9}>
            <Select fullWidth value={startType} onChange={(e) => setStartType(e.target.value)}>
              <MenuItem value="start">Immediately Show</MenuItem>
              <MenuItem value="hide">Immediately Hide</MenuItem>
              {flags.ScheduledTickers && <MenuItem value="schedule">Schedule Start</MenuItem>}  
            </Select>
          </Grid>

          {startType === "schedule" && flags.ScheduledTickers && (
            <>
              <Grid item xs={3}>
                <Box>Start Time:</Box>
              </Grid>
              <Grid item xs={9}>
                <DateTimePicker
                  value={scheduleStartTime}
                  views={['year', 'month', 'day', 'hours']}
                  onChange={(value) => value ? setScheduleStartTime(value.utc()) : setScheduleStartTime(null)}
                />
              </Grid>
            </>
          )}
        {flags.ScheduledTickers && (
          <>
            <Grid item xs={3}>
              <Box>End Time:</Box>
            </Grid>
          <Grid item xs={9}>
            <DateTimePicker
              value={scheduleEndTime}
              views={['year', 'month', 'day', 'hours']}
              onChange={(value) => value ? setScheduleEndTime(value.utc()) : setScheduleEndTime(null)}
              />
            </Grid>
          </>
        )}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button 
              variant="contained" 
              onClick={() => setIsTickerCreatorOpen(false)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button 
              variant="contained"
              onClick={() => submit()}
              disabled={isButtonDisabled()}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      </Modal>
    </AddTickerModalContext.Provider>
    
  );
};

export function useAddTickerModal() {
  const context = useContext(AddTickerModalContext);
  if (!context) {
    throw new Error('useAddTickerModal must be used within a AddTickerModalProvider');
  }
  return context;
}

