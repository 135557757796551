import * as signalR from '@microsoft/signalr';
import useFetch from "../../Components/CommonUtility/useFetch";
import { useEffect, useState } from "react";

import api from "../../Modules/APIModules";
import { Change, DataSourceUpdateList } from './DataSourceUpdateList';
import FetchModule from '../../Modules/FetchModule';
import { HttpTransportType } from '@microsoft/signalr';

export interface LiveUpdateDataSourceProps<T> {
  baseUrl: string;
  hubUrl: string;
  defaultData?: T[];
}
const apiUrl = api.getLink()
const fetchModule = new FetchModule();

export const useLiveUpdateDataSource = <T extends {id: string | number, publicId: string}>({
  baseUrl, 
  hubUrl, 
  defaultData
}: LiveUpdateDataSourceProps<T>) => {
  const { data, error, loading } = useFetch(baseUrl, true, defaultData || []);
  const [localData, setLocalData] = useState<T[]>(defaultData || []);
  // Sync useFetch data with localData
  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localData)) {
      setLocalData(data);
    }
  }, [data]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(apiUrl+hubUrl, {
        accessTokenFactory: () => {
          const token = fetchModule.getCookie("jwtToken");
          if (!token) {
            throw new Error("No token found");
          }
          return token;
        },
        transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .build();

    connection.start()

    connection.on("Add", (dto: T) => {
      setLocalData((currentData: T[]) => {
        const rowIndex = currentData.findIndex((row: T) => row.id === dto.id);
        if (rowIndex === -1) {
          return [...currentData, dto];
        }
        const newRows = [...currentData];
        newRows[rowIndex] = dto;
        return newRows;
      });
    });

    connection.on("Update", (changes: DataSourceUpdateList<T>[]) => {
      setLocalData((currentData: T[]) => {
        const newData = [...currentData];
        
        changes.forEach((change: DataSourceUpdateList<T>) => {
          const rowIndex = newData.findIndex((row: T) => row.id === change.entityId);
          if (rowIndex !== -1) {
            const updatedRow = {...newData[rowIndex]};
            change.changes.forEach((fieldChange: Change<T>) => {
              updatedRow[fieldChange.field] = fieldChange.newValue;
            });
            newData[rowIndex] = updatedRow;
          }
        });

        return newData;
      });
    });

    connection.on("Replace", (dto: T) => {
      setLocalData((currentData: T[]) => {
        const rowIndex = currentData.findIndex((row: T) => row.id === dto.id);
        if (rowIndex === -1) {
          return [...currentData, dto];
        }
        const newRows = [...currentData];
        newRows[rowIndex] = dto;
        return newRows;
      });
    });

    
    connection.on("Delete", (publicId: string) => {

      setLocalData((currentData: T[]) => {
        return currentData.filter((row: T) => row.publicId !== publicId);
      });
    });


    return () => {
      connection.stop();
    };

  }, []);

  return { 
    data: localData, 
    setData: setLocalData, 
    error, 
    loading 
  };
}