import React, { useState } from "react";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
} from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import NewBoxDialog from "./NewBoxDialog";

interface CartViewToolbarProps {
  displayType: "cardsTable" | "repackStorePreview";
}

function CartViewToolbarBase({ displayType }: CartViewToolbarProps) {
  const {
    addToSelectedBox,
    addToSelectedBoxTicket,
    createNewBox,
  } = useBuildRepacks();

  const handleConfirm = async (boxTypeId: string) => {
    await createNewBox(boxTypeId, displayType);
    setIsDialogOpen(false);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {displayType === "repackStorePreview" ? (
          <>
            <Grid item>
              <GridToolbarDensitySelector />
              <GridToolbarFilterButton />
              <Button
                onClick={() => setIsDialogOpen(true)}
              >
                Add to New Box
              </Button>
              <Button onClick={addToSelectedBox}>Add to Selected Box</Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <GridToolbarDensitySelector />
              <GridToolbarFilterButton />
              <Button
                onClick={() => setIsDialogOpen(true)}
              >
                Add to New Box
              </Button>
              <Button onClick={addToSelectedBoxTicket}>
                Add to Selected Box
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <NewBoxDialog
        isCreatingBox={false}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirm}
      />
    </GridToolbarContainer>
  );
}

export default function CartViewToolbar(
  props: GridToolbarProps & { displayType?: string }
) {
  return (
    <CartViewToolbarBase
      displayType={(props as any).displayType || "repackStorePreview"}
    />
  );
}