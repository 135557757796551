import { Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, Grid, FormControl, TextField } from '@mui/material';
import {  PickersShortcutsItem, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { useMemo, useState } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import DownloadFile from '../../Utilities/DownloadFile';
import ShowChannelAutocomplete from '../ShowChannelComponents/ShowChannelAutocomplete';
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';
import PlatformAutoComplete from '../PlatformComponents/PlatformAutocomplete';
type ShowModalProps = {
    children?: React.ReactNode;
}
const today = new Date();
const year = today.getFullYear();
const monthToday = today.getMonth()+1;
const daysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate();

const ShowSaleDownloadModal = ({} : ShowModalProps)  => {

    const month = ["noMonth", "January","February","March","April","May","June","July","August","September","October","November","December"];

    const [open, setOpen] = useState(false);
    const [filename, setFilename] = useState<string>('ShowSales' + month[monthToday] + year);

    const {platform, setPlatform, channel, setChannel} = useShow();

    const [dateRange, setDateRange] = useState<DateRange<Dayjs> | undefined>([dayjs(year+"-"+monthToday+"-01"), dayjs(year+"-"+monthToday+"-"+daysInMonth(year, monthToday))]);
    const [downloading, setDownloading] = useState(false);
    const columnOrder = [
        "Customer",
        "SaleStatus",
        "SaleType",
        "BreakId",
        "Price",
        "Quantity",
        "City",
        "State",
        "Country",
        "TimeOfSale",
        "ShowDate",
        "ProductName",
        "ProductDesc",
        "Platform",
        "Channel",
        "ShowSlot",
        "ShowTitle",
        "DisplayTitle"
    ]
    const colVis = {
        "Customer": true,
        "SaleType": true,
        "SaleStatus": true,
        "Price": true,
        "DisplayTitle": true,
        "Quantity": true,
        "City": true,
        "State": true,
        "Country": true,
        "TimeOfSale": true,
        "ShowDate": true,
        "ProductName": true,
        "ProductDesc": true,
        "Platform": true,
        "Channel": true,
        "ShowSlot": true,
        "ShowTitle": true,
        "BreakId": true
    }
    function clear() {
        setOpen(false);
        setPlatform(null);
        setChannel(null)
        setDateRange([dayjs(year+"-"+monthToday+"-01"), dayjs(year+"-"+monthToday+"-"+daysInMonth(year, monthToday))]);
        setFilename('ShowSales-'+ month[monthToday]+year);
    }
    const isDisabled = useMemo(() => {
        return dateRange === null || (dateRange != null && (dateRange[0] === null || dateRange[1] === null))
    }, [dateRange]) 
    function downloadFile() {
        if (!downloading && dateRange && dateRange[0] !== null && dateRange[1] !== null) {
            try {

                const items = [
                    {
                        field: "SaleStatus",
                        operator: "equals",
                        value: "Success"
                    },
                    {
                        field: "ShowDate",
                        operator: "onOrAfter",
                        value: dateRange?.[0]?.toISOString()
                    },
                    {   
                        field: "ShowDate",
                        operator: "onOrBefore",
                        value: dateRange?.[1]?.toISOString()
                    },
                ];
                if (platform && platform.id) {
                    items.push({
                        field: "Platform",
                        operator: "equals",
                        value: platform.platformName
                    });
                }
                if (channel && channel.id) {
                    items.push({
                        field: "Channel",
                        operator: "equals",
                        value: channel.channelName
                    });
                }

                const returnObject = {
                    Filename: filename,
                    Mode: "showSale",
                    FilterModel: {
                        items: items
                    },
                    ColumnOrder: columnOrder,
                    ColVis: colVis
                }   
                // Perform export logic here
                const url  = "/download/showsales/";
                DownloadFile(url, filename, returnObject, ".xlsx")
                setOpen(false)
            } catch (error) {
                console.error("Error downloading file:", error);
            } finally {
                setDownloading(false);
            }
        }
    }
    const handleChange = (newValue: DateRange<Dayjs> | null) => {
        if (newValue !== null) {
            const today = newValue[0];
            const lastDate = newValue[1];
            if (today && lastDate) {
                setFilename('ShowSales-' + month[today.month()+1] + today.year());
            }
            setDateRange(newValue);
        } else {
            setFilename("ShowSales");
        }
    }

    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
        {
          label: 'This Week',
          getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
          },
        },
        {
          label: 'Last Week',
          getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
          },
        },
        {
          label: 'Last 7 Days',
          getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
          },
        },
        {
          label: 'Current Month',
          getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
          },
        },
        {
          label: 'Next Month',
          getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
          },
        },
        { label: 'Reset', getValue: () => [null, null] },
      ];

    return(
        <>
            <Button onClick={() => setOpen(true)}>Download Sales</Button>
            
           <Dialog
                open={open}
                maxWidth="md"
                onClose={clear}
           >
                <DialogTitle>Download all Show Sales based these Show dates</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Filename"
                                    value={filename}
                                    defaultValue={'cards'+ Date.now()}
                                    onChange={(e) => setFilename(e.target.value)}
                                        fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <PlatformAutoComplete 
                                platform={platform}
                                setPlatform={setPlatform}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShowChannelAutocomplete/>
                          </Grid>

                          <Grid item xs={12}>
                            <StaticDateRangePicker
                                value={dateRange}
                                onChange={handleChange}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={2}
                            />
                          </Grid>

                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {clear()}} >Cancel</Button> 
                    <Button onClick={() => {downloadFile()}} disabled={isDisabled}>Download</Button> 
                </DialogActions>
           </Dialog>
        </>
    )
}
  
  
  export default ShowSaleDownloadModal;
  