import { Box, Checkbox, FormControlLabel, List, ListItem, Typography, Popover, TextField } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import "../CommonUtility/ServerSide/FilterComponents/Filter.css";
interface SelectableItem {
  id: string | number;
}

interface CheckboxSelectProps<T extends SelectableItem> {
  options: T[];
  value: T[];
  onChange: (newValue: T[]) => void;
  hasFocus: boolean;
  displayField?: keyof T;
}

export const CheckboxSelect = <T extends SelectableItem>({ options, value, onChange, hasFocus, displayField }: CheckboxSelectProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<T[]>(value || []);
  const [selectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hasFocus) {
      setIsOpen(true);
    }
  }, [hasFocus]);

  useEffect(() => {
    setSelectAll(selectedItems.length === options.length);
  }, [selectedItems, options]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      onChange([]);
    } else {
      setSelectedItems([...options]);
      onChange([...options]);
    }
    setSelectAll(!selectAll);
  };

  const handleToggleItem = (item: T) => {
    const isSelected = selectedItems.some(selected => selected.id === item.id);
    let newSelected: T[];

    if (isSelected) {
      newSelected = selectedItems.filter(selected => selected.id !== item.id);
    } else {
      newSelected = [...selectedItems, item];
    }

    setSelectedItems(newSelected);
    onChange(newSelected);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getDisplayValue = (item: T) => {
    if (!displayField) {
      return item;
    }
    return item[displayField] ? String(item[displayField]) : "";
  };

  return (
    <div ref={anchorEl}>
      <TextField
        tabIndex={0}
        value={selectedItems.length === 0 ? 'None' : 
               selectedItems.length === options.length ? 'All' : 
               selectedItems.map((item) => getDisplayValue(item)).join(", ")}
        onClick={() => setIsOpen(true)}
        InputProps={{
          readOnly: true,
          sx: {
            cursor: 'pointer',
            '&:focus': {
              outline: 'none'
            }
          }
        }}
        fullWidth
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            marginTop: '4px',
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            handleClose();
          }
        }}
      >
        <Box 
          sx={{ width: 250, bgcolor: 'background.paper' }}
        >
          <FormControlLabel
            sx={{ p: 1 }}
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                indeterminate={selectedItems.length > 0 && selectedItems.length < options.length}
              />
            }
            label={<Typography variant="body2">Select All</Typography>}
          />
          <List dense>
            {options.map((item) => {
              return (
                <ListItem key={item.id} dense>
                  <FormControlLabel
                    control={
                    <Checkbox
                      checked={selectedItems.some(selected => selected.id === item.id)}
                      onChange={() => handleToggleItem(item)}
                    />
                  }
                  label={<Typography variant="body2">{getDisplayValue(item)}</Typography>}
                />
              </ListItem>
            )})}
          </List>
        </Box>
      </Popover>
    </div>
  );
};