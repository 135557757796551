import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { RepackBoxType } from "../Types/RepackBoxType";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import React from "react";

interface NewBoxDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (boxTypeId: string) => Promise<void>;  
  isCreatingBox: boolean;
}

const NewBoxDialog: React.FC<NewBoxDialogProps> = ({
  open,
  onClose,
  onConfirm,
  isCreatingBox,
}) => {
  const { boxTypes } = useBuildRepacks();
  const [selectedBoxType, setSelectedBoxType] = React.useState<RepackBoxType>(
    boxTypes[0] || { id: 0, publicId: '', name: '' }
  );

  const handleConfirm = () => {
    onConfirm(selectedBoxType.publicId); 
  };
  

  const handleClose = () => {
    setSelectedBoxType(boxTypes[0] || { id: 0, publicId: '', name: '' });
    onClose();
  };

  const handleSelectedBoxTypeChange = (event: SelectChangeEvent<string>) => {  
    const value = event.target.value;
    if (value) {
      const selectedType = boxTypes.find((type) => type.publicId === value);  
      if (selectedType) {
        setSelectedBoxType(selectedType);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Box</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="box-type-select-label">Box Type</InputLabel>
          <Select
            fullWidth
            id="select-repack-box-type"
            value={selectedBoxType.publicId}  
            onChange={handleSelectedBoxTypeChange}
            displayEmpty
          >
            {!boxTypes?.length ? (
              <MenuItem value="" disabled>
                No box types available
              </MenuItem>
            ) : (
              boxTypes.map((type: RepackBoxType) => (
                <MenuItem key={type.id} value={type.publicId}>  
                  {type.name || "Unknown Type"}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          disabled={!selectedBoxType || isCreatingBox}
          variant="contained"
          color="primary"
        >
          {isCreatingBox ? "Creating..." : "Create Box"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewBoxDialog;