import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import {
  DataGridPro,
  GridRowsProp,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { RepackSeriesType } from "../Types/RepackSeriesType";
import { RepackBox } from "../Types/RepackBox";
import { RepackBoxType } from "../Types/RepackBoxType";
import { RepackTicketType } from "../Types/RepackTicketType";
import { GridColDef } from "@mui/x-data-grid-pro";
import { RepackItem } from "../Types/RepackItem";

interface SaveSeriesModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  boxTypes: RepackBoxType[];
  selectedType: RepackSeriesType;
  setSelectedType: React.Dispatch<React.SetStateAction<RepackSeriesType>>;
  boxes: RepackBox[];
  ticketTypes: RepackTicketType[];
}

const SaveSeriesModal: React.FC<SaveSeriesModalProps> = ({
  open,
  onClose,
  onConfirm,
  boxTypes,
  selectedType,
  setSelectedType,
  boxes,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(open);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const [boxWarnings, setBoxWarnings] = useState<Record<number, boolean>>({});
  const [editedBoxes, setEditedBoxes] = useState<RepackBox[]>([]);

  // DO NOT COMMENT -> saved for future reference so I don't forget what the different types are <3
  // const attributesByType: Record<string, string[]> = {
  //   Pantheon: [
  //     "JACKED",
  //     "JUMBO",
  //     "UNICORN",
  //     "CASE HIT",
  //     "CHAOS",
  //     "FLAW/NT",
  //     "SUPER",
  //     "GOAT",
  //   ],
  //   Prophecy: ["BLACK", "BLUE", "WHITE"],
  //   Hero: ["sabre", "reign", "legend"],
  // };

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  useEffect(() => {
    setEditedBoxes(boxes);
  }, [boxes]);

  const repackConstraints = {
    Pantheon: { min: 5, max: 5 },
    Prophecy: { exact: 10 },
    Hero: { exact: 1 },
  };

  const validateBoxes = (boxes: RepackBox[], selectedType: RepackSeriesType) => {
    const warnings: string[] = [];
    const boxWarningMap: Record<number, boolean> = {};

    const constraints =
      repackConstraints[selectedType.name as keyof typeof repackConstraints];
    if (!constraints) return { warnings, boxWarningMap };

    if ("min" in constraints || "max" in constraints) {
      const boxesWithManyItems = boxes
        .filter((b) =>
          b.items
            ? b.items.length > (constraints as { max: number }).max
            : false
        )
        .map((b) => b.id);
      if (boxesWithManyItems.length > 0) {
        warnings.push(
          `Warning: Boxes ${boxesWithManyItems.join(", ")} contain more than ${
            constraints.max
          } cards.`
        );
        boxesWithManyItems.forEach((id) => (boxWarningMap[id] = true));
      }

      const boxesWithFewItems = boxes
        .filter((b) =>
          b.items
            ? "min" in constraints &&
              b.items.length < (constraints as { min: number }).min
            : false
        )
        .map((b) => b.id);
      if (boxesWithFewItems.length > 0) {
        warnings.push(
          `Warning: Boxes ${boxesWithFewItems.join(", ")} contain fewer than ${
            (constraints as { min: number }).min
          } cards.`
        );
        boxesWithFewItems.forEach((id) => (boxWarningMap[id] = true));
      }
    }

    if ("exact" in constraints) {
      const boxesWithWrongItemCount = boxes
        .filter((b) => b.items?.length !== constraints.exact)
        .map((b) => b.id);
      if (boxesWithWrongItemCount.length > 0) {
        warnings.push(
          `Warning: Boxes ${boxesWithWrongItemCount.join(
            ", "
          )} do not contain exactly ${constraints.exact} cards.`
        );
        boxesWithWrongItemCount.forEach((id) => (boxWarningMap[id] = true));
      }
    }

    return { warnings, boxWarningMap };
  };

  useEffect(() => {
    const { warnings, boxWarningMap } = validateBoxes(boxes, selectedType as RepackSeriesType);
    setWarningMessages(warnings);
    setBoxWarnings(boxWarningMap);
  }, [selectedType, boxes]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    setIsModalOpen(false);
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
      const selectedTypeName = event.target.value;
      const newSelectedType =
        boxTypes.find((type) => type.name === selectedTypeName) ||
        ({} as RepackBoxType);
      setSelectedType(newSelectedType);
      setEditedBoxes((prevBoxes) =>
        prevBoxes.map((b) => ({ ...b, attributes: [] }))
      );
    };


  const boxColumns: GridColDef[] = [
    { field: "id", headerName: "Box ID", width: 150 },
    { field: "comp", headerName: "Comp", width: 250 },
    { field: "total cards", headerName: "Total Cards", width: 250 },
  ];

  const boxRows: GridRowsProp = editedBoxes.map((b: RepackBox) => ({
    id: b.id,
    name: b.id,
    items: b.items,
    hasWarning: boxWarnings[b.id] || false,
  }));

  const hasWarnings = warningMessages.length > 0;
  const isTypeSelected = selectedType && selectedType.name !== "";

  return (
    <Dialog open={isModalOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Confirm Save</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to save the new series?</Typography>
        {warningMessages.length > 0 && (
          <Box sx={{ marginTop: 2 }}>
            {warningMessages.map((message, index) => (
              <Typography key={index} color="error" variant="body2">
                {message}
              </Typography>
            ))}
          </Box>
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-series-type-label">
            Select a Series Type
          </InputLabel>
          <Select
            labelId="select-series-type-label"
            value={selectedType.name}
            label="Select a Series Type"
            onChange={handleTypeChange}
            sx={{ width: "100%" }}
          >
            {boxTypes.map((type) => (
              <MenuItem key={type.id} value={type.name}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isTypeSelected && (
          <Typography color="error" variant="body2">
            Please select a series type.
          </Typography>
        )}
        <Box sx={{ height: 400, width: "100%", marginTop: 2 }}>
          <DataGridPro
            rows={boxRows}
            columns={boxColumns}
            pagination={false}
            disableColumnReorder
            onDetailPanelExpandedRowIdsChange={(newIds) =>
              setDetailPanelExpandedRowIds(newIds)
            }
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            getDetailPanelContent={(row) => (
              <div style={{ padding: 10 }}>
                <DataGridPro
                  rows={row.row.items.map((item: RepackItem) => ({
                    ...item,
                  }))}
                  columns={[
                    {
                      field: "inventoryId",
                      headerName: "Inventory ID",
                      width: 150,
                    },
                    { field: "name", headerName: "Name", width: 250 },
                    { field: "team", headerName: "Team", width: 150 },
                    { field: "comp", headerName: "Comp", width: 250 },
                  ]}
                  pagination={false}
                  disableColumnReorder
                  autoHeight
                />
              </div>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={!isTypeSelected || hasWarnings}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSeriesModal;
