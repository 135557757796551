// DashboardSidebar.js
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faBars,
  faMoneyBill,
  faHouse,
  faUsers,
  faBarcode,
  faBoxesStacked,
  faLandmark,
  faFileLines,
  faGear,
  faHouseLaptop,
  faIdCardClip,
  faChartLine,
  faMasksTheater,
  faCashRegister,
  faUpload,
  faKeyboard,
  faTicket,
  faVideo,
  faEye,
  faChevronDown,
  faChevronUp,
  faSquareRss,
  faComputer
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import {
  Box,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavItem } from "./NavItem";
import { library } from "@fortawesome/fontawesome-svg-core";
import { styled } from "@mui/material/styles";
import { DashboardSidebarContext } from "../../Pages/ReactContexts/NavigateContext";
import DashboardSettings from "./DashboardSettings";
import { useFeatureFlags } from "../../Contexts/FeatureFlagContexts/FeatureFlagContext";
import pjson from "../../../package.json";
import { PermissionRoles } from "../CommonUtility/PermissionFlagsEnum";

library.add(
  faCircleUser,
  faBars,
  faMoneyBill,
  faLandmark,
  faHouse,
  faUsers,
  faBarcode,
  faBoxesStacked,
  faFileLines,
  faGear,
  faHouseLaptop,
  faIdCardClip,
  faChartLine,
  faMasksTheater,
  faCashRegister,
  faUpload,
  faKeyboard,
  faVideo,
  faTicket,
  faEye,
  faChevronDown,
  faChevronUp,
  faSquareRss,
  faComputer
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const drawerWidth = DashboardSettings.drawerWidth;
const MiniDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DashboardSidebar = () => {
  const { flags } = useFeatureFlags();
  const [repackOpen, setRepackOpen] = useState(false);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const { isSidebarOpen, setSidebarOpen } = useContext(DashboardSidebarContext);

  function onClose() {
    setSidebarOpen(false);
  }

  const handleRepackClick = () => {
    setRepackOpen(!repackOpen);
  };

  const items = [
    {
      icon: <FontAwesomeIcon icon="house" />,
      title: "Dashboard",
      href: "/dashboard",
      feature: "dashboard",
    },
    {
      icon: <FontAwesomeIcon icon="id-card-clip" />,
      title: "Single Cards",
      href: "/dashboard/cards",
      feature: "cards",
      role: PermissionRoles.CARDS,
    },
    {
      icon: <FontAwesomeIcon icon="upload" />,
      title: "SKU Correction",
      href: "/dashboard/sku/correction",
      feature: "cards",
      role: PermissionRoles.CARDS,
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={repackOpen ? "chevron-up" : "chevron-down"}
        />
      ),
      title: "Repacks",
      href: "/dashboard/repack",
      feature: "repack",
      onClick: handleRepackClick,
      role: PermissionRoles.REPACK,
    },
    repackOpen && {
      icon: <FontAwesomeIcon icon="ticket" />,
      title: "Repack Tickets",
      href: "/dashboard/repack/tickets",
      feature: "repack",
      role: PermissionRoles.REPACK,
      nested: true,
    },
    repackOpen && {
      title: "View Repacks",
      href: "/dashboard/repack/view",
      feature: "repack",
      icon: <FontAwesomeIcon icon="eye" />,
      role: PermissionRoles.REPACK,
      nested: true,
    },
    {
      icon: <FontAwesomeIcon icon="video" />,
      title: "Shows",
      href: "/dashboard/shows",
      feature: "shows",
      role: PermissionRoles.COMMISSION,
    },
    {
      icon: <FontAwesomeIcon icon="gear" />,
      title: "Settings",
      href: "/dashboard/settings",
      feature: "settings",
    },
    {
      icon: <FontAwesomeIcon icon="barcode" />,
      title: "Missing Barcode Search",
      href: "/dashboard/barcode",
      feature: "barcode",
    },
    {
      icon: <FontAwesomeIcon className="menu-icon" icon="cash-register" />,
      title: "Pay Period",
      href: "/dashboard/payperiod",
      feature: "shows",
      role: PermissionRoles.COMMISSION,
    },
    {
      icon: <FontAwesomeIcon icon="fa-solid fa-square-rss" />,
      title: "Ticker",
      href: "/dashboard/ticker",
      feature: "ticker",
      role: PermissionRoles.MARKETING,
    },
    {
      icon: <FontAwesomeIcon icon="fa-solid fa-computer" />,
      title: "Stream Computers",
      href: "/dashboard/stream-pc",
      feature: "ticker",
      role: PermissionRoles.DEV,
    }
  ];



  const content = (
    <>
      <DrawerHeader>
        <div>
          <Typography color="inherit" variant="subtitle1">
            {isSidebarOpen ? "WeTheHobby" : "WTH"}
          </Typography>
        </div>
      </DrawerHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
            alignItems: "center",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: 1,
            alignItems: "center",
          }}
        >
          {items.filter(Boolean).map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
              feature={item.feature}
              flags={flags}
              role={item.role}
              nested={item.nested}
              onClick={item.onClick}
              isCollapsed={!isSidebarOpen}
            />
          ))}
        </Box>

        <Divider sx={{ borderColor: "#2D3748" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
            textAlign: "center",
            marginTop: isSidebarOpen ? "75%" : "300%",
            maxWidth: "100%",
          }}
        >
          <div className="version-box">
            {isSidebarOpen && ( 
              <div className="version-text">
                <Typography color="neutral.100" variant="subtitle2">
                  Version {pjson.version}
                </Typography>
              </div>
            )}
          </div>

          <div className="discord-box">
            <FontAwesomeIcon
              className="discord-icon"
              icon={faDiscord}
              style={{
                fontSize: isSidebarOpen ? "24px" : "24px", 
              }}
            />
            {isSidebarOpen && ( 
              <div className="discord-text">
                <Typography color="neutral.100" variant="subtitle2">
                  Report Bugs in Discord
                </Typography>
                <Typography color="neutral.200" variant="body2">
                  #dashboard-techsupport
                </Typography>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <MiniDrawer
        anchor="left"
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </MiniDrawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={isSidebarOpen}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default function () {
  return <DashboardSidebar />;
}