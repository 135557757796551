import React from "react";
import Box from "@mui/material/Box";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import "./Repack.css";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import { useRepackCreatorCard } from "../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import CartViewToolbar from "./CartViewToolbar";

export default function RepackStorePreview() {
  const { setSelectedCardIds, selectedCardIds } = useBuildRepacks();
  const { rows, setRows } = useRepackCreatorCard();

  const columns: GridColDef[] = [
    { field: "inventoryId", headerName: "Inv Id", editable: false },
    { field: "name", headerName: "Card", editable: false, width: 400 },
    { field: "comp", headerName: "Comp", type: 'number', editable: false },
    { field: "totalCost", headerName: "Total Cost", type: 'number', editable: false },
  ];

  return (
    <CollapsiblePaper
      title="Available Cards"
      openTitle="View Available Cards"
      closeTitle="Hide Available Cards"
    >
      <Box sx={{ width: "100%" }}>
        <DataGridPremium
          loading={rows.length === 0}
          sx={{
            height: "400px",
          }}
          slots={{
            toolbar: CartViewToolbar,
          }}
          slotProps={{
            toolbar: { displayType: "repackStorePreview" },
            row: {
              draggable: "true",
            },
          }}
          density="compact"
          rows={rows}
          onFetchRows={() => {
            setRows(rows);
          }}
          columns={columns}
          pagination={false}
          disableColumnReorder={true}
          checkboxSelection
          disableVirtualization={false}
          rowBufferPx={100}
          onRowSelectionModelChange={(ids) => {
            setSelectedCardIds([...ids] as number[]);
          }}
          rowSelectionModel={selectedCardIds}
        />
      </Box>
    </CollapsiblePaper>
  );
}
