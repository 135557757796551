import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackBox } from "../Types/RepackBox";
import { RepackBoxType } from "../Types/RepackBoxType";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { RepackItem } from "../Types/RepackItem";
import {
  DeleteItem,
  CreateNewBox,
  DeleteRepackBox,
  UpdateRepackBox,
  UpdateItem,
  CreateNewItemType,
  AddTicketToCard,
  RemoveTicketFromCard,
} from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";
import { RepackItemType } from "../Types/RepackItemType";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import { useRepackCreatorCard } from "../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { RepackTicket } from "../Types/RepackTicket";
import TicketSelectionDialog from "./TicketSelectionDialog";
import { Card } from "../../SinglesPage/Types/CardType";
import DataGridAutocomplete from "../../../Components/Autocomplete/DatagridAutocomplete";

const StagedRepackPreview = () => {
  const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
  const [selectedRepackItem, setSelectedRepackItem] =
    useState<RepackItem | null>(null);
  const [searchParams] = useSearchParams();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [isAddingToCase, setIsAddingToCase] = useState(false);
  const [boxRows, setBoxRows] = useState<RepackBox[]>([]);
  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();
  const {
    boxTypes,
    setSelectedBox,
    selectedBox,
    itemTypes,
    setItemTypes,
    boxes,
    setBoxes,
    setSelectedSeriesId,
    box,
    setBox,
    boxLoading,
    selectedCase,
    setAllTickets,
    availableTickets,
  } = useBuildRepacks();
  const { setRows } = useRepackCreatorCard();

  const [selectedBoxType, setSelectedBoxType] = useState<any>(
    boxTypes[0]?.id | 1
  );
 

  useEffect(() => {
    const newBoxRows = (boxes || []).map((b: RepackBox) => ({
      ...b,
    }));
    setBoxRows(newBoxRows);
  }, [boxes]);

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds, setSelectedSeriesId, seriesId]);

  const handleAddTicketToCardClick = (item: RepackItem) => {
    setSelectedRepackItem(item);
    setTicketDialogOpen(true);
  };

  const handleRemoveTicketFromCard = async (item: RepackItem) => {
    try {
      const response = await RemoveTicketFromCard(item.publicId, user);

      if (!response.ok) {
        throw new Error("Failed to remove ticket from card");
      }

      const updatedData = await response.json();

      if (!updatedData?.result) {
        throw new Error("Invalid response data");
      }

      setBox((prevBox) => {
        if (!prevBox?.repackItems) return prevBox;
        return {
          ...prevBox,
          repackItems: prevBox.repackItems.map((i) =>
            i.publicId === item.publicId ? updatedData.result : i
          ),
        };
      });

      setBoxes((prevBoxes) => {
        if (!prevBoxes) return prevBoxes;
        return prevBoxes.map((box) => ({
          ...box,
          repackItems: box.repackItems?.map((i) =>
            i.publicId === item.publicId ? updatedData.result : i
          ),
        }));
      });

      if (item.repackTicket) {
        setAllTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.publicId === item.repackTicket?.publicId
              ? { ...ticket, repackItemId: undefined, repackItem: undefined }
              : ticket
          )
        );
      }

      newSnackbarMessage("Ticket removed successfully", "success");
    } catch (error) {
      console.error("Error removing ticket:", error);
      newSnackbarMessage("Failed to remove ticket", "error");
    }
  };

  const handleTicketSelect = async (ticketId: string) => {
    if (!selectedRepackItem) return;

    try {
      const response = await AddTicketToCard(
        selectedRepackItem.publicId,
        ticketId,
        user
      );

      if (!response.ok) {
        throw new Error("Failed to add ticket to card");
      }

      const updatedItem = await response.json();

      if (!updatedItem?.result) {
        throw new Error("Invalid response data");
      }

      setBox((prevBox) => {
        if (!prevBox?.repackItems) return prevBox;
        return {
          ...prevBox,
          repackItems: prevBox.repackItems.map((item) =>
            item.publicId === selectedRepackItem.publicId
              ? updatedItem.result
              : item
          ),
        };
      });

      setBoxes((prevBoxes) => {
        if (!prevBoxes) return prevBoxes;
        return prevBoxes.map((box) => {
          if (!box.repackItems) return box;
          return {
            ...box,
            repackItems: box.repackItems.map((item) =>
              item.publicId === selectedRepackItem.publicId
                ? updatedItem.result
                : item
            ),
          };
        });
      });

      setAllTickets((prevTickets) => {
        if (!prevTickets) return prevTickets;
        return prevTickets.map((ticket) =>
          ticket.publicId === ticketId
            ? { ...ticket, repackItemId: updatedItem.result.id }
            : ticket
        );
      });

      newSnackbarMessage("Ticket successfully added to card", "success");
    } catch (error) {
      console.error("Error adding ticket to card:", error);
      newSnackbarMessage("Failed to add ticket to card", "error");
    } finally {
      setTicketDialogOpen(false);
      setSelectedRepackItem(null);
    }
  };

  const mapRepackItemTypeName = (item: RepackItem) => {
    const type = itemTypes.find(
      (type: RepackItemType) => type.id === item.repackItemTypeId
    );
    return type ? type.name : "Unknown Type";
  };

  const handleSelectedBoxTypeChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    const value = event.target.value;
    if (value !== "") {
      setSelectedBoxType(value as number);
    }
  };

  const handleUpdateRepackBox = async (updatedRow: RepackBox) => {
    try {
      const b = updatedRow ?? [];
      const items = b.items ?? [];
      const response = await UpdateRepackBox(updatedRow, items, user);

      if (response.ok) {
        newSnackbarMessage("Repack box updated successfully!", "success");
        const updatedBoxes = boxes?.map((rb: RepackBox) =>
          rb.id === updatedRow.id ? { ...rb, ...updatedRow } : rb
        );
        return updatedBoxes;
      } else {
        newSnackbarMessage("Failed to update repack box.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack box:", error);
      newSnackbarMessage("Error updating repack box.", "error");
    }
  };

  const handleCreateNewItemType = async (newTypeName: string) => {
    try {
      const response = await CreateNewItemType(newTypeName, user);
      if (response.ok) {
        const newType = await response.json();
        if (newType?.result) {
          const updatedTypes = [...itemTypes, newType.result];
          setItemTypes(updatedTypes);
          return newType.result;
        }
      }
      newSnackbarMessage("Failed to create new item type.", "error");
    } catch (error) {
      console.error("Failed to create new item type:", error);
      newSnackbarMessage("Error creating new item type.", "error");
      return null;
    }
  };

  const handleUpdateRepackItems = async (updatedRow: RepackItem) => {
    try {
      if (!updatedRow || !updatedRow.id) {
        console.error("Invalid row data:", updatedRow);
        newSnackbarMessage("Invalid item data for update.", "error");
        return null;
      }

      const response = await UpdateItem(user, updatedRow);

      if (response.ok) {
        const rData = await response.json();
        const rDataResult = rData.result;

        setBox((prevBox) => {
          if (!prevBox?.repackItems) return prevBox;
          const updatedItems = [...prevBox.repackItems].map((item) =>
            item.id === updatedRow.id
              ? {
                  ...item,
                  repackItemType: updatedRow.repackItemType,
                  repackItemTypeId: updatedRow.repackItemTypeId,
                  slotNumber: updatedRow.slotNumber,
                }
              : item
          );

          updatedItems.sort(
            (a, b) => (a.slotNumber || 0) - (b.slotNumber || 0)
          );

          return {
            ...prevBox,
            repackItems: updatedItems,
          };
        });

        setBoxes((prevBoxes) => {
          if (!prevBoxes) return prevBoxes;
          return prevBoxes.map((box) => ({
            ...box,
            repackItems:
              box.id === selectedBox?.id
                ? [...(box.repackItems || [])]
                    .map((item) =>
                      item.id === updatedRow.id
                        ? {
                            ...item,
                            repackItemType: updatedRow.repackItemType,
                            repackItemTypeId: updatedRow.repackItemTypeId,
                            slotNumber: updatedRow.slotNumber,
                          }
                        : item
                    )
                    .sort((a, b) => (a.slotNumber || 0) - (b.slotNumber || 0))
                : box.repackItems,
          }));
        });

        newSnackbarMessage("Repack Item updated successfully!", "success");
        return rDataResult;
      } else {
        newSnackbarMessage("Failed to update repack item.", "error");
        return updatedRow;
      }
    } catch (error) {
      console.error("Failed to update repack item:", error);
      newSnackbarMessage("Error updating repack item.", "error");
      return updatedRow;
    }
  };

  const handleDeleteItem = async (item: RepackItem, user: any) => {
    try {
      const response = await DeleteItem(item, user);
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      const updatedBoxData = await response.json();
      const updatedBox = updatedBoxData.result;

      if (item.repackTicket) {
        await new Promise<void>((resolve) => {
          setAllTickets((prevTickets: RepackTicket[]) => {
            const updatedTickets = prevTickets.map((ticket) =>
              ticket.publicId === item?.repackTicket?.publicId
                ? { ...ticket, repackItemId: undefined, repackItem: undefined }
                : ticket
            );
            resolve();
            return updatedTickets;
          });
        });
      }

      setBox(updatedBox);
      setSelectedBox(updatedBox);

      setBoxes((prevBoxes) =>
        prevBoxes?.map((b: RepackBox) =>
          b.id === updatedBox.id ? updatedBox : b
        )
      );
      if (item.repackCard) {
        setRows((prev: Card[]) => [...prev, item.repackCard]);
      }

      newSnackbarMessage("Item deleted successfully!", "success");
    } catch (error) {
      console.error("Error deleting item:", error);
      newSnackbarMessage("Failed to delete item.", "error");
    }
  };

  const handleCreateBox = async () => {
    if (!selectedBoxType) {
      newSnackbarMessage("Please select a repack type.", "error");
      return;
    }

    try {
      const type = boxTypes.find(
        (type: RepackBoxType) => type.id === selectedBoxType
      );
      if (!type) {
        newSnackbarMessage("Invalid repack type selected.", "error");
        return;
      }
      const response = await CreateNewBox(user, type, seriesId);

      if (response.ok) {
        newSnackbarMessage("Box created successfully!", "success");
        const newBox = await response.json();
        const newBoxes = [...(boxes || []), newBox.result];
        setBoxes(newBoxes);
      } else {
        newSnackbarMessage("Failed to create box.", "error");
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      newSnackbarMessage("Error creating box.", "error");
    }
  };

  const handleAddToCase = async () => {
    if (isAddingToCase) return;

    if (!selectedCase) {
      newSnackbarMessage("No case selected.", "error");
      return;
    }

    try {
      setIsAddingToCase(true);
      if (!selectedBox) {
        newSnackbarMessage("No box selected.", "error");
        return;
      }
      const items = selectedBox.items ?? [];
      const response = await UpdateRepackBox(
        selectedBox,
        items,
        user,
        selectedCase.publicId
      );
      if (response.ok) {
        newSnackbarMessage("Box added to case successfully!", "success");
        const updatedBoxes = boxes?.filter(
          (b: RepackBox) => b.id !== selectedCase.id
        );
        setBoxes(updatedBoxes);
      } else {
        newSnackbarMessage("Failed to add box to case.", "error");
      }
    } catch (error) {
      console.error("Failed to add box to case:", error);
      newSnackbarMessage("Error adding box to case.", "error");
    } finally {
      setIsAddingToCase(false);
    }
  };

  const handleDeleteRepackBox = async () => {
    const prevBox = selectedBox;
    if (!prevBox) {
      newSnackbarMessage("No box selected.", "error");
      return;
    }

    try {
      const response = await DeleteRepackBox(selectedBox, user);
      if (response.ok) {
        const removedItemsJson = await response.json();

        if (removedItemsJson && typeof removedItemsJson === "object") {
          const removedTickets = prevBox.repackItems?.reduce(
            (tickets: RepackTicket[], item) => {
              if (item.repackTicket) {
                tickets.push(item.repackTicket);
              }
              return tickets;
            },
            []
          );

          await new Promise<void>((resolve) => {
            setAllTickets((prev: RepackTicket[]) => {
              const updatedTickets = [...prev];
              removedTickets?.forEach((ticket: RepackTicket) => {
                const index = updatedTickets.findIndex(
                  (t) => t.publicId === ticket.publicId
                );
                if (index !== -1) {
                  updatedTickets[index] = {
                    ...updatedTickets[index],
                    repackItemId: undefined,
                  };
                } else {
                  updatedTickets.push({
                    ...ticket,
                    repackItemId: undefined,
                  });
                }
              });
              return updatedTickets;
            });
            resolve();
          });

          const removedCards = prevBox.repackItems
            ?.filter((item) => item.repackCard)
            .map((item) => item.repackCard);

          setRows((prev: Card[]) => [...prev, ...(removedCards || [])]);

          setExpandedRowIds([]);
          setSelectedBox(null);
          setBoxes((prev) =>
            prev?.filter((b: RepackBox) => b.id !== prevBox.id)
          );

          newSnackbarMessage("Box deleted successfully!", "success");
        }
      } else {
        throw new Error("Failed to delete box");
      }
    } catch (error) {
      console.error("Error deleting box:", error);
      newSnackbarMessage("Failed to delete box.", "error");
    }
  };

  const handleBoxSelection = (boxId: GridRowId | null) => {
    if (!boxId) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
      return;
    }

    if (boxId === selectedBox?.id) {
      handleBoxSelection(null);
      return;
    }

    const foundBox = boxes?.find((rb: RepackBox) => rb.id === boxId);
    if (!foundBox) {
      console.error("Box not found");
      newSnackbarMessage("Box not found.", "error");
      return;
    }

    setExpandedRowIds([boxId]);
    setSelectedBox(foundBox);
    setBox(foundBox);
  };

  const handleRowClick = (params: GridRowParams) => {
    handleBoxSelection(params.id);
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    if (ids.length === 0) {
      handleBoxSelection(null);
    } else if (ids.length === 1) {
      handleBoxSelection(ids[0]);
    } else if (ids.length === 2) {
      newSnackbarMessage(
        "Please close the other box before opening this one.",
        "warning"
      );
      handleBoxSelection(ids[1]);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 50,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "repackBoxType",
      headerName: "Repack Type",
      width: 100,
      valueGetter: (params) => params.row.repackBoxType?.name || "",
    },
    {
      field: "itemCount",
      headerName: "Items",
      width: 50,
      valueGetter: (params) => params.row.repackItems?.length || 0,
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard?.comp) {
            return acc + Number(item.repackCard.comp);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard?.totalCost) {
            return acc + Number(item.repackCard.totalCost);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard) {
            const comp = Number(item.repackCard.comp || 0);
            const cost = Number(item.repackCard.totalCost || 0);
            return acc + (comp - cost);
          }
          return acc;
        }, 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "containsSpecialItems",
      headerName: "Special Items",
      width: 100,
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.some((item: RepackItem) => {
          const typeName = (item.repackItemType?.name || "").trim().toLowerCase();
          return typeName !== "" && typeName !== "card" && typeName !== "ticket";
        })
          ? "Yes"
          : "No";
      },
    }
  ];

  const itemColumns: GridColDef[] = [
    {
      field: "slotNumber",
      headerName: "Slot",
      width: 80,
      editable: true,
      type: "number",
      valueGetter: (params) => {
        return (
          params.row.slotNumber ||
          params.api.getSortedRowIds().indexOf(params.id) + 1
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      valueGetter: (params) => {
        const item = params.row;
        const cardName = item.repackCard?.name || "";
        const ticketName = item.repackTicket?.repackTicketType?.name || "";

        if (item.repackCard && item.repackTicket) {
          return `${cardName} (with ${ticketName})`;
        }

        return cardName || ticketName || "Unknown Item";
      },
    },
    {
      field: "cardComp",
      headerName: "Card Comp",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        return Number(item.repackCard?.comp || 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "cardCost",
      headerName: "Card Cost",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        return Number(item.repackCard?.totalCost || 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "cardDifference",
      headerName: "Card Diff",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        const comp = Number(item.repackCard?.comp || 0);
        const cost = Number(item.repackCard?.totalCost || 0);
        return comp - cost;
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "ticketCost",
      headerName: "Ticket Cost",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        return Number(item.repackTicket?.cost || 0);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "totalValue",
      headerName: "Total Value",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        const cardComp = Number(item.repackCard?.comp || 0);
        const ticketCost = Number(item.repackTicket?.cost || 0);
        return cardComp + ticketCost;
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        const cardCost = Number(item.repackCard?.totalCost || 0);
        const ticketCost = Number(item.repackTicket?.cost || 0);
        return cardCost + ticketCost;
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "totalDifference",
      headerName: "Total Diff",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        const cardComp = Number(item.repackCard?.comp || 0);
        const cardCost = Number(item.repackCard?.totalCost || 0);
        const ticketCost = Number(item.repackTicket?.cost || 0);
        return cardComp + ticketCost - (cardCost + ticketCost);
      },
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value);
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: true,
      valueGetter: (params) => mapRepackItemTypeName(params.row),
      renderEditCell: (params) => (
        <DataGridAutocomplete
          data={itemTypes}
          labelPath="name"
          value={itemTypes.find(type => type.name === params.value) || null}
          id={params.publicId}
          field={params.field}
          row={params.row}
          handleChange={async (event, newValue) => {
            if (!newValue) return;

            if (typeof newValue === "string" && newValue.startsWith('Add "')) {
              const typeName = newValue.slice(5, -1);
              const newType = await handleCreateNewItemType(typeName);
              if (newType) {
                const updatedRow = {
                  ...params.row,
                  repackItemType: newType,
                  repackItemTypeId: newType.id,
                };
                const result = await handleUpdateRepackItems(updatedRow);
                if (result) {
                  params.api.setEditCellValue({
                    id: params.id,
                    field: params.field,
                    value: newType.name,
                  });
                }
              }
              return;
            }

            const updatedRow = {
              ...params.row,
              repackItemType: newValue,
              repackItemTypeId: newValue.id,
            };
            const result = await handleUpdateRepackItems(updatedRow);
            if (result) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: newValue.name,
              });
            }
          }}
          handleAddToData={(field, valueToAdd) => {
            return handleCreateNewItemType(valueToAdd);
          }}
          width="150px"
        />
      ),
    },
    {
      field: "attachedTicket",
      headerName: "Attached Ticket",
      width: 150,
      valueGetter: (params) => {
        const ticket = params.row.repackTicket;
        if (!ticket) return "None";
        return `${ticket.repackTicketType?.name || "Unknown"} ($${
          ticket.cost
        })`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        const item = params.row;
        return (
          <Box>
            <Button
              color="secondary"
              onClick={() => handleDeleteItem(params.row, user)}
            >
              <DeleteIcon />
            </Button>
            {item.repackCard && !item.repackTicket && (
              <Button
                color="primary"
                onClick={() => handleAddTicketToCardClick(item)}
              >
                Add Ticket
              </Button>
            )}
            {item.repackCard && item.repackTicket && (
              <Button
                color="error"
                onClick={() => handleRemoveTicketFromCard(item)}
              >
                Remove Ticket
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const getDetailPanelContent = () => {
    const rowItems = [...(box?.repackItems ?? [])].sort(
      (a, b) => (a.slotNumber || 0) - (b.slotNumber || 0)
    );

    if (!rowItems.length) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="flex"
          flexDirection="column"
        >
          <Grid item padding={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50%"
            >
              {boxLoading && <CircularProgress />}
              {!boxLoading && (
                <Typography variant="h6" color="textSecondary">
                  There are no items in this box yet
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleDeleteRepackBox}>
              <DeleteIcon />
              Delete Box
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid>
        <DataGridPro
          getRowId={(row) => row.id}
          rows={rowItems}
          columns={itemColumns}
          processRowUpdate={(newRow) => {
            return handleUpdateRepackItems(newRow);
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing item update:", error);
            newSnackbarMessage("Error processing item update.", "error");
          }}
        />
        <Divider />
        <Button variant="text" onClick={handleDeleteRepackBox}>
          <DeleteIcon />
          Delete Box
        </Button>
      </Grid>
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Select
            fullWidth
            id="select-repack-box-type"
            value={selectedBoxType}
            onChange={handleSelectedBoxTypeChange}
            displayEmpty
          >
            {!boxTypes?.length ? (
              <MenuItem value="" disabled>
                No box types available
              </MenuItem>
            ) : (
              boxTypes.map((type: RepackBoxType) => (
                <MenuItem key={type.publicId} value={type.id}>
                  {type.name || "Unknown Type"}
                </MenuItem>
              ))
            )}
          </Select>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={handleCreateBox}>
            Add New Box
          </Button>
          <Button
            variant="text"
            onClick={handleAddToCase}
            disabled={isAddingToCase}
          >
            {isAddingToCase ? "Adding to Case..." : "Add To Selected Case"}
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  return (
    <CollapsiblePaper
      title="Staged Boxes Preview"
      closeTitle="Close Preview"
      openTitle="Open Preview"
    >
      <div style={{ height: "auto", minHeight: "400px", width: "100%" }}>
        <DataGridPro
          getRowId={(row) => row.id}
          rows={boxRows}
          columns={columns}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 250}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
          disableMultipleRowSelection={true}
          onRowClick={handleRowClick}
          processRowUpdate={(newRow) => {
            return handleUpdateRepackBox(newRow);
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing box update:", error);
            newSnackbarMessage("Error processing box update.", "error");
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
      <TicketSelectionDialog
        open={ticketDialogOpen}
        onClose={() => setTicketDialogOpen(false)}
        onSelect={handleTicketSelect}
        availableTickets={availableTickets}
      />
    </CollapsiblePaper>
  );
};

export default StagedRepackPreview;
