import { Box, Button, Modal, TextField, Select, MenuItem, List,ListItem,ListItemText } from '@mui/material';
import { useState } from 'react';
import DownloadFile from '../../Utilities/DownloadFile';
import DashboardDefault from './CardOrderDefaults/DashboardDefault';
import MasterDefault from './CardOrderDefaults/MasterDefault';

const CardExport = (props) => {
  const [open, setOpen] = useState(false);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}`;
  const [filename, setFilename] = useState(`CardsExport-${formattedDate}`);
  const [mode, setMode] = useState('DashboardStandard');
  const filterModel = {...props.filterModel,items:props.filterModel.items.filter(item => item.value)};
  const handleOpen = () => {
    setOpen(true);
  };
  const blacklistedVis = {
    "actions": true,
  }

  const handleClose = () => {
    setOpen(false);
  };

  const getOrder = (mode) =>{
    var order = null;
    switch (mode) {
      case 'DashboardStandard':
      order =  DashboardDefault().order;
      break;
      case 'MasterFile':
      order =  MasterDefault().order;
      break;
      default:
      order =  props.colOrder;
      break;
    }
    
    order = order.map(item => item.charAt(0).toUpperCase() + item.slice(1));
    return order;
  }
  
  const getVis = (mode) =>{
    var vis  = null
    switch (mode) {
      case 'DashboardStandard':
        vis  = DashboardDefault().visibility;
        break;
      case 'MasterFile':
        vis  = MasterDefault().visibility;
        break;
      default:
        // also needs all columns that are not in there to be sent as well
        vis =  props.colVis;
        props.colOrder.forEach(item => {
          if(!vis[item] && !blacklistedVis[item]) {
            vis[item] = true;
          }
        })

        break;
    }
        Object.keys(vis).forEach(key => {
          var newKey = key.charAt(0).toUpperCase() + key.slice(1);
          vis[newKey] = vis[key];
          delete vis[key];
    });
    return vis;
  }

  const handleExport = () => {
    var returnObject = {}
    returnObject.Filename = filename
    returnObject.Mode = mode
    returnObject.FilterModel = filterModel
    returnObject.ColumnOrder = getOrder(mode)
    returnObject.ColVis = getVis(mode)
    
    // Perform export logic here
    const url  = "/download/cards/";
    DownloadFile(url, filename, returnObject, ".csv")

    handleClose();
  };

  return (
    <div>
      <Button variant="text" onClick={handleOpen}>
        Export
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          boxShadow: 24, 
          p: 4 
        }}>
          <List>
            {filterModel.items.map((filterItem) => (
              <ListItem key={filterItem.columnField}>
                <ListItemText
                  primary={`Column Field: ${filterItem.field}`}
                  secondary={`Operator Value: ${filterItem.operator}, Value: ${filterItem.value}`}
                />
              </ListItem>
            ))}
          </List>
          <TextField
            label="Filename"
            value={filename}
            defaultValue={'cards'+ Date.now()}
            onChange={(e) => setFilename(e.target.value)}
            fullWidth
          />
          <Select
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            fullWidth
            defaultValue='DashboardStandard'
          >
            <MenuItem value={'MasterFile'} title="Formated like old MasterFile">Master File Layout</MenuItem>
            <MenuItem value={'DashboardStandard'} title="Formated like default Dashboard">Dashboard Standard Layout</MenuItem>
            <MenuItem value={'DashboardCustom'} title="Formated like your current Dashboard">Current Dashboard Layout</MenuItem>
          </Select>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>

            <Button variant="contained" onClick={handleExport}>
              Export
            </Button>
            
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CardExport;