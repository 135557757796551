import { Box } from "@mui/material";

import { BuildRepacksProvider } from "../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import ViewRepacks from "../Dash/RepackPage/ViewRepacks/ViewRepacks";
import { CardsProvider } from "../Contexts/CardsContext/CardsContext";
import { RepackCreatorCardPreviewProvider } from "../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";

const ViewRepackPage = () => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      padding: "5px",
    }}
  >
    <CardsProvider>
      <RepackCreatorCardPreviewProvider>
        <BuildRepacksProvider>
          <ViewRepacks />
        </BuildRepacksProvider>
      </RepackCreatorCardPreviewProvider>
    </CardsProvider>
  </Box>
);

export default ViewRepackPage;
