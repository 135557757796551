import { Autocomplete, darken, lighten, Paper, styled, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium"
import { useGridApiContext as useGridApiContextPro } from "@mui/x-data-grid-pro";
import { AutocompleteHelper } from "./ObjectExtractor";
import { matchSorter } from "match-sorter";
import { useMemo } from "react";
import { DataGridAutocompleteProps } from "./DatagridAutocompleteTypes";




const DataGridAutocomplete = ({
  isGrouped = false,
  isMultiselect = false,
  labelPath = "",
  groupPath = "origin",
  data,
  width = "300px",
  handleChange,
  value,
  id,
  field,
  handleAddToData,
  typePublicId,
  propogateOnBlur,
  row,
  premium = false,
  restrictInput = false
}: DataGridAutocompleteProps) => {
  const ApiContext = premium ? useGridApiContext : useGridApiContextPro;
  const apiRef = ApiContext();

  const isAddOption = (option: string) => option.startsWith("Add") && option.endsWith('"');
  const extractNewValue = (option: string) => option.slice(5, option.length - 1);
  const setValueTo = (newValue: any) => {
    // if newvalue isn't an array and isMultiselect is true we should not update the new value (to prevent errors)
    if (! (isMultiselect && !Array.isArray(newValue))) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };

  //Default handleChange event handler
  const getDataGridChange = (event: any, newValue: any) => {
    const testString = AutocompleteHelper.getLabel(newValue, labelPath);
    if (isAddOption(testString || "") && handleAddToData) {
      const valueToModal = extractNewValue(testString || "");
      
      handleAddToData(field, valueToModal, setValueTo,typePublicId);
      setValueTo(valueToModal);
    } else {
      setValueTo(newValue);
      propogate(newValue);
    }
  };

  const filterData = (options:any,valueToFilterOn:any)=>{
    if(labelPath !== ""){
      return matchSorter(options, valueToFilterOn, { keys: [labelPath] }).splice(0, 15);
    }
    return matchSorter(options, valueToFilterOn).splice(0, 15);
  }

  const filterDataGrouped = (options:any,valueToFilterOn:any, origins:any)=>{
    let retList: any[] = []
    origins.forEach((origin:any) => {
      const temp = options.filter((obj:any) => obj.origin === origin);
      if(labelPath !== ""){
        const objTemp = matchSorter(temp, valueToFilterOn, { keys: [labelPath] }).splice(0, 15)
        retList = retList.concat(objTemp);
      }
      else{
        const baseTemp = matchSorter(temp, valueToFilterOn).splice(0, 15);
        retList = retList.concat(baseTemp);
      }
    })
    const databaseItems = retList.filter(item => item.origin === "Database");
    const nonDatabaseItems = retList.filter(item => item.origin !== "Database");
    retList = [...nonDatabaseItems, ...databaseItems];
    return retList;
  }

  //handleFilterOptions function
  const handleFilterOptions = (options: any, params: any) => {
    
    let valueToFilter = AutocompleteHelper.getLabel(params.inputValue, labelPath) || "";
    const compVal = AutocompleteHelper.getLabel(value, labelPath) || "";
    if (compVal !== "" && valueToFilter === "" && compVal !== undefined) {
      valueToFilter = AutocompleteHelper.getLabel(value, labelPath) || "";
    }
    const filtered = filterData(options,valueToFilter);

    if (handleAddToData && params.inputValue) {
      filtered.push(`Add "${params.inputValue}"`);
    }

    return filtered;
  };

  const handleFilterOptionsGroupCase = (options: any, params: any) => {
    
    let valueToFilter = AutocompleteHelper.getLabel(params.inputValue, labelPath) || "";
    const compVal = AutocompleteHelper.getLabel(value, labelPath) || "";
    if (compVal !== "" && valueToFilter === "" && compVal !== undefined) {
      valueToFilter = AutocompleteHelper.getLabel(value, labelPath) || "";
    }
    const origins = new Set(options.map((obj:any) => obj.origin));  
    const filtered = filterDataGrouped(options,valueToFilter,origins);

    if (handleAddToData && params.inputValue) {
      filtered.push(`Add "${params.inputValue}"`);
    }

    return filtered;
  };
 

  const handleKeyDown = (event: any) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      if (event.key === "Enter") {
        event.stopPropagation();
      }
      const inputValue = event.target.value;
      const options = data;
      const filtered = filterData(options,inputValue);

      if (filtered.length > 0) {
        handleChange?.(event, filtered[0]);
        event.target.blur();
      }
    }
  };
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });
  const renderGroup = useMemo(() => isGrouped ? (params: any) => {
    return (
    <li key={params.key}>
      <GroupHeader>{params.group}</GroupHeader>
      <GroupItems>{params.children}</GroupItems>
    </li>
  )} : undefined, [isGrouped]);

  const renderOption = useMemo(() => !isGrouped ? (props: any, option: any) => {
    const label = AutocompleteHelper.getLabel(option, labelPath) || "Blank / Empty";
    return (
      <li {...props} style={{ fontSize: "12px", whiteSpace: "nowrap" }} key={option.publicId}>{label}</li>
    );
  } : undefined, [isGrouped, labelPath]);

  handleChange = handleChange ?? getDataGridChange;

  const propogate = (val:any) =>{
    if(propogateOnBlur){
      const rowToFilterOn = {...row,[field]:val};
      propogateOnBlur(rowToFilterOn);
    }
  }

  return (
    <Autocomplete
      options={data}
      multiple={isMultiselect}
      size="small"
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo = {!restrictInput}
      value={value}

      filterOptions={!isGrouped ? handleFilterOptions: handleFilterOptionsGroupCase}
      renderInput={(params) => (
        <TextField
          {...params}
          id="grade"
          type="text"
          size="small"
          style={{ fontSize: "14px", width }}
          placeholder={"Placeholder"}
        />
      )}
      PaperComponent={({ children }) => (
        <Paper style={{ border: "2px solid #5048E5", top: "-1000px" }}>{children}</Paper>
      )}
      renderGroup={renderGroup}
      renderOption={renderOption}
      getOptionLabel={(option) => AutocompleteHelper.getLabel(option, labelPath) || ""}
      groupBy={isGrouped ? (option: any) => AutocompleteHelper.getGroup(option, groupPath) : undefined}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default DataGridAutocomplete;