// CardActionsInputGroup.tsx
import React from "react";
import { Button, Tooltip, TextField } from "@mui/material";
import { InputGroup } from "reactstrap";
import CreatePurchaseOrderButton from "./CreatePurchaseOrderButton";
import { Card } from "../Types/CardType";
import { Copy } from "./CopyContent";
import AddCards from "./AddCards";
import CreateBulkCards from "./CreateBulkCards";
import CardImportButton from "../../../Components/UtilityButtons/CardImporterButton";

interface CardActionsInputGroupProps {
  cardsToAdd: number;
  setCards: (value: number) => void;
  createRecords: (type: "grade" | "raw" | "pending" | "retail") => void;
  verifyRecord: () => void;
  setOpen: (value: boolean) => void;
  fetchPromise: any;
  selectedRows: Card[];
  open: boolean;
  setSnackbar: (value: any) => void;
  rows: any[];
  setRows: Function;
  setSelectedRows: (rows: Card[]) => void;
  createPantheon: () => void;
  repackOn: boolean;
  addToRepackStore: (rows: Card[]) => void;
  removeFromRepackStore: (row: any) => void;
  clearRepackStore: () => void;
  filterModel: any;
  setFilterModel: any;
  refresh: any;
  setRefresh: any;
  deselect: any;
  users: any;
}

const CardActionsInputGroup: React.FC<CardActionsInputGroupProps> = ({
  cardsToAdd,
  setCards,
  createRecords,
  verifyRecord,
  setOpen,
  fetchPromise,
  selectedRows,
  open,
  setSnackbar,
  rows,
  setRows,
  setSelectedRows,
  filterModel,
  setFilterModel,
  refresh,
  setRefresh,
  deselect,
}) => {
  const handleChange = (event: any) => {
    if (filterModel && filterModel.items && event.target.value !== "0") {
      const updatedFilterModel = {
        ...filterModel,
        items: [
          {
            field: "inventoryId",
            operator: "=",
            value: event.target.value ? event.target.value : "",
          },
        ],
      };
      setFilterModel(updatedFilterModel);
      setRefresh(!refresh);
    }
  };

  return (
    <InputGroup className="input-group">
      <TextField
        id="outlined-basic"
        className="search-by-id-input"
        label="Search By Id"
        variant="outlined"
        type="number"
        size="small"
        onChange={(event) => {
          handleChange(event);
        }}
      />
      <AddCards
        createRecords={createRecords}
        cardsToAdd={cardsToAdd}
        setCards={setCards}
      />
      <CreateBulkCards />
      <CardImportButton />

      <Copy selectedRows={selectedRows} copyType="short" />
      <Copy selectedRows={selectedRows} copyType="purchase" />
      <Copy selectedRows={selectedRows} copyType="master" />
      <Copy selectedRows={selectedRows} copyType="all" />

      <Tooltip title="This functionality is currently disabled">
        <span>
          <Button color="primary" onClick={verifyRecord} disabled={true}>
            Add to Dear
          </Button>
        </span>
      </Tooltip>
      <CreatePurchaseOrderButton
        setOpen={setOpen}
        fetchPromise={fetchPromise}
        selectedRows={selectedRows}
        open={open}
        setSnackbar={setSnackbar}
        rows={rows}
        setRows={setRows}
        setSelectedRows={setSelectedRows}
        deselect={deselect}
      />
    </InputGroup>
  );
};

export default CardActionsInputGroup;
