import {toast } from 'react-toastify';
import api from "./APIModules";
export default class FetchModule {

getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res
}
      

////// NEW FETCH MODULES FOR MUIs snackbar toast promise thing


muiFetch = async(link, type, data) => {
    const token = String(this.getCookie("jwtToken"));
    const response = await 
        fetch(api.getLink() +link, {
            method: type,
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
                'Content-Type': 'application/json',
                'user': localStorage.getItem('user')
            }),
            body: JSON.stringify(data)
        })
    return response;
}
// Honestly should be a generic like the one above where you're not stringifying the data
// before and just send the data with all the appropriate values and send the form part of it
// violates DRY
muiFetchFormData = async(link, type, selectedFile) => {
    const token = String(this.getCookie("jwtToken"));
    const formData = new FormData();
	formData.append('File', selectedFile);
	const response = await fetch(
        api.getLink()+ link,
			{
				method: type,
                headers: new Headers({
                    'Authorization': "Bearer " + String(token),
                    'user': localStorage.getItem('user')
                }),
				body: formData,
			}
		)
	return response;
}
filesFormData = async (link, type, formData) => {
    const token = String(this.getCookie("jwtToken"));
    const userId = JSON.parse(localStorage.getItem('userData'))["id"];
    const response = await fetch(
        api.getLink() + link,
        {
            method: type,
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
                'user': localStorage.getItem('user'),
                'userId': userId
            }),
            body: formData,
        }
    )
    return response;
}
    async fetchResponse (link) {
        const response = await fetch(api.getLink() + link, {
            method: "GET",
            header: new Headers({
            })
        });
        return response;
    }
    async fetchResponseAuthed(link, type) {
        const token = String(this.getCookie("jwtToken"));
        if (type == "GET") {
        const response = await fetch(api.getLink() +link, {
            method: type,
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
            })
        });
        return response;
        } else {
            const response = await toast.promise(
                fetch(api.getLink() +link, {
                    method: type,
                    headers: new Headers({
                        'Authorization': "Bearer " + String(token),
                    })
                }),
                {
                    pending: 'Request pending',
                    success: 'Saved successfully',
                    error: 'Failed'
                }
            )
            return response
        }
    }
    async fetchProducts(link, type, name) {
        const token = String(this.getCookie("jwtToken"));
        const response = await fetch(api.getLink() +link, {
            method: type,
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
                'name': JSON.stringify(name)
            })
        });
        return response;
    }
    async postResponseKey (link) {
        const response = await toast.promise(
            fetch(api.getLink() + link, {
                method: 'POST',
                headers: new Headers({
                    'key': 'Kof52s3qVMwoLTzmu4L3YEK3YreCE6t5',
                })
            }),
            {
                pending: 'Request pending',
                success: 'Saved successfully',
                error: 'Failed'
            }
        )
        return response;
    }
    // d
    async postResponseBody(link, type, data) {
        const token = String(this.getCookie("jwtToken"));
        const response = await toast.promise(
            fetch(api.getLink() +link, {
                method: type,
                headers: new Headers({
                    'Authorization': "Bearer " + String(token),
                    'Content-Type': 'application/json',
                    'user': localStorage.getItem('user')
                }),
                body: JSON.stringify(data)
            }),
            {
                pending: 'Request pending',
                success: 'Saved successfully',
                error: 'Failed'
            }
        )
        return response;
    }
    //d
    async putResponseAuthed(link, data) {
        const token = String(this.getCookie("jwtToken"));
        const response = await toast.promise(
            fetch(api.getLink() +link, {
                method: "PUT",
                headers: new Headers({
                    'Authorization': "Bearer " + String(token),
                    'Content-Type': 'application/json',
                    'user': localStorage.getItem('user')
                }),
                body: JSON.stringify(data)
            }),
            {
                pending: 'Request pending',
                success: 'Saved successfully',
                error: 'Failed'
            }
        )
        return response;
    }
    async postNoCors(link, type, data) {
        const token = String(this.getCookie("jwtToken"));
        const response = await fetch(api.getLink() +link, {
            method: type,
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
                'Content-Type': 'application/json',
                'user': localStorage.getItem('user')
            }),
            body: JSON.stringify(data)
        });
        return response;
    }
    putPromise = async(link, body) => {
        let response = await this.putResponseAuthed(link, body)
        
        return response
    }
    NEWOLDpromise = async(link, authed, type, body) => {
        if (authed) {
            const token = String(this.getCookie("jwtToken"));
            const response = await toast.promise(
                fetch(api.getLink() +link, {
                    method: type,
                    headers: new Headers({
                        'Authorization': "Bearer " + String(token),
                        'Content-Type': 'application/json',
                        'user': localStorage.getItem('user')
                    }),
                    body: JSON.stringify(body)
                }),
                {
                    pending: 'Request pending',
                    success: 'Saved successfully',
                    error: 'Failed'
                }
            )
            return response;
        } else {
            const response = await toast.promise(
                fetch(api.getLink() +link, {
                    method: type,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify(body)
                }),
                {
                    pending: 'Request pending',
                    success: 'Saved successfully',
                    error: 'Failed'
                }
            )
            return response
        }
    }
    promise = async(link, authed, type, body) => {
        let response;
        if (type == "PUT") {
            response = await this.putResponseAuthed(link, body)
        } else if (type == "POST") {
            response = await this.postResponseBody(link, type, body)
        } else if (type == "POST2") {
            response = await this.postResponseKey(link)
        }
        else {
            response = authed == true ? 
                await this.fetchResponseAuthed(link, type) 
                : await this.fetchResponse(link);
        }
        return response;
    }

}
