import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-premium";
import useFetch from "../../Components/CommonUtility/useFetch";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import { useUser } from "../../Components/CommonUtility/UserContext";
import FetchModule from "../../Modules/FetchModule";
import DataGridAutocomplete from "../../Components/Autocomplete/DatagridAutocomplete";
import { useLiveUpdateDataSource } from "../../Utilities/LiveUpdateDataSource/LiveDatasource";
import { ChannelInfo } from "../../Types/DataModels/ShowChannel/TickerChannelInfo";

const fetchData = new FetchModule();
export default function StreamComputerDashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [computerName, setComputerName] = useState("");
  const { data: channels, } = useFetch("/Show/channels/all", true, []);
  const { data: machines, } = useLiveUpdateDataSource({baseUrl: "/Machine/machines/all", hubUrl: "/hubs/machine", defaultData: []});
  const { user } = useUser();

  const handleSubmit = async () => {
    await fetchData.promise("/Machine/machines/create", true, "POST", {
      UserId: user["id"],
      MachineName: computerName,
    });
    handleClose();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setComputerName("");
  };

  function CustomToolbar() {
    return (
    
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "left"}}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Add Stream Computer
        </Button>
        </Box>
        
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'machineName', headerName: 'Machine Name', width: 150 },
    { 
      field: 'channelList', 
      headerName: 'Channels', 
      renderEditCell: (params: GridRenderCellParams) => 
        <DataGridAutocomplete 
          id={params.id} 
          field="channelList" 
          value={params.value} 
          data={channels} 
          labelPath="channelName"
          row={params.row}
          premium
          isMultiselect
        />,
      editable: true,
      width: 500,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.channelList.map((channel: ChannelInfo) => channel.channelName).join(", ");
        return <Typography>{value}</Typography>;
      }
    },
  ];

  const handleRowUpdate = async (newRow: GridRowModel) => {
    const machine = newRow.publicId;
    const channelList = newRow.channelList.map((channel: ChannelInfo) => channel.publicId);
    await fetchData.promise("/Machine/machines/assign-channel", true, "POST", {
      MachineId: machine,
      ChannelList: channelList,
      UserId: user["id"],
    });
    return newRow;
  }
  return (
    <>
      <DataGridPremium
        rows={machines}
        columns={columns}
        slots={{
          toolbar: CustomToolbar,
        }}
        processRowUpdate={handleRowUpdate}
      />

      <Dialog open={isModalOpen} onClose={handleClose}>
        <DialogTitle>Add New Stream Computer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Computer Name"
            fullWidth
            value={computerName}
            onChange={(e) => setComputerName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            disabled={!computerName.trim()} // Disable if empty
            variant="contained"
          >
            Add Computer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}