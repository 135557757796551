import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material/styles";
import { UserProvider } from "./Components/CommonUtility/UserContext";
import { theme } from "./Theme/theme";
import { PageRouter } from "./Pages/PageRouter";
import { SnackbarProvider } from "./Contexts/SnackbarContext/SnackbarContext";
import { FeatureFlagProvider } from "./Contexts/FeatureFlagContexts/FeatureFlagContext";
LicenseInfo.setLicenseKey(
  "ada3659a97381d8565d08d63b37ae820Tz05MjY2OSxFPTE3NTAyNzE3OTMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div className="main">
    <ThemeProvider theme={theme}>
      <UserProvider>
        <FeatureFlagProvider>
          <SnackbarProvider>
            <PageRouter />
          </SnackbarProvider>
        </FeatureFlagProvider>
      </UserProvider>
    </ThemeProvider>
  </div>
);

reportWebVitals();
