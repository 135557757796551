import {
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type CollapsiblePaperProps = {
  children?: React.ReactNode;
  loading?: boolean;
  title: string;
  closeTitle: string;
  openTitle: string;
};

const CollapsiblePaper = ({
  children,
  loading = false,
  title,
  closeTitle,
  openTitle,
}: CollapsiblePaperProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ margin: 16 }}>
        <Paper style={{ padding: 8, position: "relative" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 8,
              minWidth: 0,
            }}
          >
            <Typography
              variant="h5"
              style={{
                flexShrink: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {expanded ? closeTitle : openTitle}
              </Typography>
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </div>
          </div>
          <Collapse in={expanded}>{children}</Collapse>
        </Paper>
      </div>
    </div>
  );
};

export default CollapsiblePaper;
