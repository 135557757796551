import React, { createContext, useContext, useState, ReactNode } from "react";
import FetchModule from "../../../Modules/FetchModule";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackTicket } from "../../../Dash/RepackPage/Types/RepackTicket";
import { RepackTicketType } from "../../../Dash/RepackPage/Types/RepackTicketType";
import { RepackSeries } from "../../../Dash/RepackPage/Types/RepackSeries";
import { RepackTicketPrize } from "../../../Dash/RepackPage/Types/RepackTicketPrize";

interface PrizeRequest {
  Name: string;
  Cost: number;
  ProductId: number | string;
}

const fetchModule = new FetchModule();
interface RepackTicketContextType {
  ticketName: string;
  setTicketName: (name: string) => void;
  cost: number | string;
  setCost: (cost: number | string) => void;
  multipleTickets: boolean;
  setMultipleTickets: (multiple: boolean) => void;
  numberOfTickets: number | string;
  setNumberOfTickets: (number: number | string) => void;
  ticketType: RepackTicketType;
  setTicketType: (ticketType: RepackTicketType) => void;
  ticketInputs: { name: string; cost: string }[];
  setTicketInputs: (inputs: { name: string; cost: string }[]) => void;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  prizes: RepackTicketPrize[];
  setPrizes: React.Dispatch<React.SetStateAction<RepackTicketPrize[]>>;
  prizeCosts: number[];
  setPrizeCosts: (costs: number[]) => void;
  series: RepackSeries;
  setSeries: (series: RepackSeries) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  processRowUpdate: (newRow: any, oldRow: any) => Promise<any>;
  handleAddTickets: () => void;
}

const RepackTicketContext = createContext<RepackTicketContextType | undefined>(undefined);

export const RepackTicketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const fetchData = new FetchModule();
  const { setTickets, tickets} = useBuildRepacks();
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();

  const [ticketName, setTicketName] = useState<string>("");
  const [cost, setCost] = useState<number | string>("");
  const [multipleTickets, setMultipleTickets] = useState<boolean>(false);
  const [numberOfTickets, setNumberOfTickets] = useState<number | string>("");
  const [ticketType, setTicketType] = useState<RepackTicketType>({
    name: "Diamond",
    id: 0,
    });
  const [ticketInputs, setTicketInputs] = useState<{ name: string; cost: string }[]>([]);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [prizes, setPrizes] = useState<RepackTicketPrize[]>([]);
  const [prizeCosts, setPrizeCosts] = useState<number[]>([]);
  const [series, setSeries] = useState<RepackSeries>({ id: 0 });
  const [quantity, setQuantity] = useState<number>(0);

  const processRowUpdate = async (newRow: any, oldRow: any) => {
    const updatedRow = { ...oldRow, ...newRow };
    try {
      const response = await fetchData.postResponseBody(
        "/repack/update/ticket",
        "POST",
        {
          requestData: {
            ModifiedById: user["id"],
          },
          Name: updatedRow.name,
          Cost: updatedRow.cost,
          Id: updatedRow.id,
          UserId: user["id"],
        }
      );

      if (response.ok) {
        const updatedTickets = tickets.map((ticket) =>
          ticket.id === updatedRow.id ? updatedRow : ticket
        );
        setTickets(updatedTickets);
        newSnackbarMessage("Ticket updated successfully.", "success");
      } else {
        throw new Error("Failed to update ticket");
      }
    } catch (error : any) {
      newSnackbarMessage("Failed to update ticket.", error);
    }
    return updatedRow;
  };

  const handleAddTickets = async () => {
    setLoading(true);
  
    try {
      const newTickets: RepackTicket[] = [];
      const response = await fetchModule.postResponseBody(
        "/repack/new/ticket",
        "POST",
        {
          Name: ticketType.name,
          Cost: Number(cost),
          ModifiedById: user["id"],
          RepackTicketTypeId: ticketType.publicId,
          NumberOfTickets: quantity,
          RepackSeriesId: series.publicId,
          Prizes: prizes.map((prize): PrizeRequest => ({
            Name: prize.name,
            Cost: Number(prize.cost),
            ProductId: prize.productId || "",
          })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add ticket(s)");
      }
  
      const data = await response.json();
  
      for (let i = 0; i < quantity; i++) {
        const newTicket: RepackTicket = {
          id: Date.now() + i,
          cost: Number(cost),
          modifiedBy: data[i].modifiedBy,
        };
        newTickets.push(newTicket);
      }
  
      setTickets([...tickets, ...data]);
      setTicketName("");
      setCost("");
      setNumberOfTickets("");
      newSnackbarMessage("Ticket(s) added successfully.", "success");
    } catch (error : any) {
      newSnackbarMessage("Failed to add ticket(s).", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RepackTicketContext.Provider
      value={{
        ticketName,
        setTicketName,
        cost,
        setCost,
        multipleTickets,
        setMultipleTickets,
        numberOfTickets,
        setNumberOfTickets,
        ticketType,
        setTicketType,
        ticketInputs,
        setTicketInputs,
        expanded,
        setExpanded,
        loading,
        setLoading,
        prizes,
        setPrizes,
        prizeCosts,
        setPrizeCosts,
        series,
        setSeries,
        quantity,
        setQuantity,
        processRowUpdate,
        handleAddTickets,
      }}
    >
      {children}
    </RepackTicketContext.Provider>
  );
};

export const useRepackTicketContext = () => {
  const context = useContext(RepackTicketContext);
  if (!context) {
    throw new Error("useRepackTicketContext must be used within a RepackTicketProvider");
  }
  return context;
};
