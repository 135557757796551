import React, { useCallback } from "react";
import "../Hamburger.css";
import FetchModule from "../../Modules/FetchModule";
import { Button, ButtonGroup } from "reactstrap";
import { useState, useEffect } from "react";
import { GridRowModes } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {
  Snackbar,
  Paper,
  Popper,
  Typography,
  Box,
  Alert,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CustomTextInput from "./CardsHomeComponents/CustomTextInput";
import StatusSelect from "./FilterComponents/CustomStatusFilter";
import AutocompleteAttributes from "./AutocompleteComponents/AttributesAutocomplete";
import "./singles.css";
// Buttons
import UploadChecklistButton from "./FooterComponents/UploadChecklist/UploadChecklistButton";

// Utility Functions
import { useLocalStorage } from "../../Utilities/LocalStorage/useLocalStorage";
import useFetchPromise from "../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../Components/CommonUtility/UserContext";
import CardActionsInputGroup from "./ToolbarComponents/CardActionsInputGroup";
import CustomDateInput from "./CardsHomeComponents/CustomDateInput.tsx";
import CustomNumberInput from "./CardsHomeComponents/CustomNumberInput.js";

import DashboardDefault from "./CardOrderDefaults/DashboardDefault";
import ViewChecklistButton from "./FooterComponents/ViewChecklistButton";
import { useFeatureFlags } from "../../Contexts/FeatureFlagContexts/FeatureFlagContext";
import { SkuCorrectionProvider } from "./SkuCorrection/SkuCorrectionContext";
import SkuCorrectionModal from "./SkuCorrection/SkuCorrectionModal";
import { useCardDashboard } from "./CardDashboardContext";
import { useAddPlayerModal } from "./AutocompleteAddModals/AddPlayerModalContext";
import { useAddSkuModal } from "./AutocompleteAddModals/AddSkuModalContext";
import { LocalStorageKey } from "../../Utilities/LocalStorage/LocalStorageEnum";
import { useCards } from "../../Contexts/CardsContext/CardsContext";
import DataGridComponent from "./CardsHomeComponents/DataGridComponent";
import DataGridAutocomplete from "../../Components/Autocomplete/DatagridAutocomplete";

const fetchData = new FetchModule();

/*
    This class is used for all the single cards. It's the entire page
    for the /singlecards page and uses MUI to display the data
    in a way that makes sense and is customizable nad saves any changes
    local storage so that you can always come back to the same page you
    left on a bresponseer when you are changing column sizes, filters, etc.
*/

export default function SinglesPage() {
  const { flags } = useFeatureFlags();
  const repackOn = flags.repack;
  const { user } = useUser();
  const {
    vendors,
    setVendors,
    players,
    users,
    statusTypes,
    skuList,
    modalOpen,
    updateVendors,
    updateSkus,
    teams,
    recalculateFilter,
    deleteChecklistEntry,
    getChecklistData,
    findTeams,
  } = useCardDashboard();
  const { launchAddPlayerModal } = useAddPlayerModal();
  const { launchAddSkuModal } = useAddSkuModal();
  const [setModalOpen] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [autoDeselect] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const fetchPromise = useFetchPromise();
  const [refresh, setRefresh] = useState(false);
  const [headCard] = useState(null);

  const {
    rows,
    setRows,
    goTo,
    goToViaURLParams,
    paginationModel,
    setCurrentPage,
    setPageSize,
    sortModel,
    filterModel,
    setFilterModel,
  } = useCards();

  useEffect(() => {
    goToViaURLParams("card");
  }, [fetchPromise, filterModel, paginationModel, refresh, sortModel]);

  const handleRowSelection = (newSelection) => {
    const newSelectedRows = selectedRows.map((oldRow) => {
      const alreadyExists = newSelection.find((row) => row.id === oldRow.id);
      if (alreadyExists) {
        return { ...alreadyExists };
      }
      return { ...oldRow }; // Copy the row data
    });

    // Set the selected rows
    setSelectedRows(newSelectedRows);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params);
    setRefresh(!refresh);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = event?.target?.value ?? event;
    setPageSize(newPageSize);
    setCurrentPage(1);
    setRefresh(!refresh);
  };

  // Snack bar variables
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [columnWidths, setColumnWidths, isMountedColumnWidths] =
    useLocalStorage(
      LocalStorageKey.CARD_DASHBOARD_WIDTH,
      DashboardDefault().width
    );
  const [colOrder, setColOrder, isMountedColOrder] = useLocalStorage(
    LocalStorageKey.CARD_DASHBOARD_ORDER,
    DashboardDefault().order
  );

  // Doing the same thing but with column visibility
  const [columnFilterDisplayModel, setColumnFilter] = useLocalStorage(
    LocalStorageKey.CARD_DASHBOARD_VISIBILITY,
    DashboardDefault().visibility
  );
  function setColumnVisibility(newModel) {
    setColumnFilter(newModel);
  }

  const [rowState, setRowState] = React.useState({});
  const [cardsToAdd, setCards] = React.useState(1);
  const [rowChanged, setRowChanged] = React.useState(false);
  const [updatedValues, setUpdatedValues] = React.useState({}); // Lift state up

  const handleRowEditStart = (params) => {
    recalculateFilter(params.row);
    setRowState(params.row);
  };

  function findRowById(array, rowId) {
    for (let i = 0; i < array.length; i++) {
      const currentObject = array[i];
      if (currentObject["inventoryId"] === rowId) {
        return currentObject;
      }
    }
    return null;
  }

  React.useEffect(() => {
    const valuesChanged =
      JSON.stringify(rowState) !== JSON.stringify(updatedValues);
    setRowChanged(valuesChanged);
  }, [rowState, updatedValues]);

  const handleRowEditStop = (params, event, isScrolling) => {
    if (isScrolling || modalOpen) {
      event.defaultMuiPrevented = true;
      return;
    }
    const rowId = params.row.inventoryId;
    findRowById(rows, rowId);
    setUpdatedValues(params.row);

    if (rowChanged) {
      deleteChecklistEntry(rowId);
      event.defaultMuiPrevented = false;
    } else {
      event.defaultMuiPrevented = true;
    }
  };
  const getRowNum = (params) => {
    const row = params.model;
    let maxListLength = 0;
    for (const key in row) {
      if (Array.isArray(row[key]) && row[key].length > maxListLength) {
        maxListLength = row[key].length;
      }
    }
    return maxListLength;
  };
  const getRowHeight = (params) => {
    if (rowModesModel[params.id] && rowModesModel[params.id].mode === "edit") {
      const rowNum = getRowNum(params);
      return 25 * rowNum;
    }
    return 25;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = React.useCallback(
    (id) => () => {
      const row = findRowById(rows, id);
      recalculateFilter(row);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = React.useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleMoveGradeClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, myType: "moveGrade" },
      });
    },
    [rowModesModel]
  );

  const handleMoveRawClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, myType: "moveRaw" },
      });
    },
    [rowModesModel]
  );

  const handleCancelClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rowModesModel, rows]
  );

  const [warningOpen, setWarningOpen] = React.useState(false);
  const [resolveOnClose, setResolveOnClose] = useState(null);
  const [rejectOnCancel, setRejectOnCancel] = useState(null);
  const [nextInvId, setNextInvId] = useState(false);

  const interpretStatusChange = (oldCard, newCard) => {
    var retString = null;
    if (
      oldCard.inventoryId > 20000000 &&
      oldCard.inventoryId < 30000000 &&
      newCard.status.value === "InInventory" &&
      (oldCard.status.value === "PendingConfirmation" ||
        oldCard.status.value === "")
    ) {
      if (
        newCard.grade.value === "raw" ||
        newCard.grade.value === "null" ||
        newCard.grade.value === ""
      ) {
        retString = "moveRaw";
      } else {
        retString = "moveGrade";
      }
    } else if (
      oldCard.inventoryId < 20000000 &&
      oldCard.inventoryId > 10000000 &&
      oldCard.grade?.value === "" &&
      !(newCard.grade?.value === "")
    ) {
      retString = "moveGrade";
    }
    return retString;
  };

  const [moveCardDialog, setMoveCardDialog] = React.useState("");

  const MoveCardConfirmationModal = useCallback(() => {
    return (
      <Dialog
        open={warningOpen}
        onClose={() => {
          setWarningOpen(false);
          if (resolveOnClose) {
            resolveOnClose();
            setResolveOnClose(null);
          }
        }}
      >
        <DialogTitle>
          {"You are about to automatically move a card"}
        </DialogTitle>
        <DialogContent>{moveCardDialog}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWarningOpen(false);
              if (resolveOnClose) {
                resolveOnClose();
                setResolveOnClose(null);
              }
            }}
          >
            Okay
          </Button>
          <Button
            onClick={() => {
              setWarningOpen(false);
              if (rejectOnCancel) {
                rejectOnCancel();
                setRejectOnCancel(null);
              }
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [warningOpen, moveCardDialog, nextInvId]);

  const manageConfirmationMessage = (moveType, data) => {
    if (moveType === "moveGrade") {
      setMoveCardDialog(
        "You are about to move a card from pending to graded. This will automatically update the card in the inventory system from its current inventory id to " +
          data +
          ". Are you sure you want to do this?"
      );
    } else if (moveType === "moveRaw") {
      setMoveCardDialog(
        "You are about to move a card from pending to raw. This will automatically update the card in the inventory system from its current inventory id to " +
          data +
          ". Are you sure you want to do this?"
      );
    }
  };

  const [openSkuCorrection, setOpenSkuCorrection] = useState(false);
  const [skuCorrectionData, setSkuCorrectionData] = useState([]);
  const [urlToSend, setUrlToSend] = useState({});
  const [bodyToSend, setBodyToSend] = useState({});

  const convertAccessField = (field) => {
    if (field === "Set") {
      return "setVariety";
    }
    return field.charAt(0).toLowerCase() + field.slice(1);
  };

  const cardNeedsSkuDefs = (card) => {
    const itemsToFix = [];
    const compFields = [
      "Year",
      "Manufacturer",
      "Set",
      "Series",
      "Grade",
      "League",
    ];
    compFields.forEach((field) => {
      const cardField = convertAccessField(field);
      const item = skuList[field].items.find(
        (item) => item.publicId === card[cardField]?.publicId
      );
      if (item?.label !== "" && item?.value === "") {
        card[cardField].type = cardField;
        itemsToFix.push(card[cardField]);
      }
    });
    return itemsToFix;
  };

  const processRowUpdate = React.useCallback(
    async (newRow, oldRow) => {
      const toFix = cardNeedsSkuDefs(newRow);
      var refRow = rowModesModel[newRow.id];
      if (toFix.length > 0) {
        const updatedRow = { ...newRow, isNew: false };
        updatedRow.totalCost =
          updatedRow.cost + updatedRow.fees + updatedRow.gradingFees;
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setOpen(false);
        setSkuCorrectionData(toFix);
        setOpenSkuCorrection(true);

        if (
          rowModesModel[newRow.id] &&
          rowModesModel[newRow.id]["mode"] === "view" &&
          !("myType" in refRow)
        ) {
          setUrlToSend({ url: "/Card/update", method: "PUT" });
          setBodyToSend({
            body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
            JumpType: isMovingFromPending,
          });
        } else if (rowModesModel[newRow.id]) {
          setUrlToSend({ url: "/Card/update", method: "PUT" });
          setBodyToSend({
            Body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
          });
        }

        return updatedRow;
      }

      if (!open) {
        setOpen(true);
        const updatedRow = { ...newRow, isNew: false };
        updatedRow.totalCost =
          updatedRow.cost + updatedRow.fees + updatedRow.gradingFees;
        var message = "";
        var seekLocation = "";
        var refRow = rowModesModel[newRow.id];
        var isMovingFromPending = interpretStatusChange(oldRow, newRow);
        if (
          !(isMovingFromPending === null || isMovingFromPending === undefined)
        ) {
          const res = await fetchData.fetchResponseAuthed(
            "/Card/get/NextInvId/" + isMovingFromPending,
            "GET"
          );
          if (res.ok) {
            const data = await res.json();
            setNextInvId(data);
            manageConfirmationMessage(isMovingFromPending, data);
            setWarningOpen(true);
            try {
              await new Promise((resolve, reject) => {
                setResolveOnClose(() => resolve);
                setRejectOnCancel(() => reject);
              });
            } catch {
              setRows(rows.map((row) => (row.id === oldRow.id ? oldRow : row)));
              setOpen(false);
              return oldRow;
            }
          }
        }
        if (
          rowModesModel[newRow.id] &&
          rowModesModel[newRow.id]["mode"] === "view" &&
          !("myType" in refRow)
        ) {
          await fetchPromise("/Card/update", "PUT", {
            body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
            JumpType: isMovingFromPending,
          });
          setRefresh(!refresh);
          message = "User successfully saved";
        } else if (rowModesModel[newRow.id]["myType"].includes("move")) {
          await fetchPromise("/Card/update/MoveCard", "POST", {
            Body: {
              card: updatedRow,
              type: rowModesModel[newRow.id]["myType"],
            },
            UserId: user["id"],
            Guid: newRow.publicId,
          });
          if (newRow.row?.inventoryId >= 20000000) {
            seekLocation = "pending";
          } else {
            seekLocation = "graded";
          }
          message = "User successfully moved card";
        } else if (rowModesModel[newRow.id]) {
          await fetchPromise("/Card/update", "PUT", {
            Body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
          });
          setRefresh(!refresh);
          message = "User successfully saved";
        }
        setSnackbar({ children: message, severity: "success" });
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setOpen(false);
        if (seekLocation) {
          goTo(seekLocation);
        }
        return updatedRow;
      }
    },
    [fetchPromise, open, refresh, rowModesModel, rows, user]
  );

  //todo: cannot read props of undef set here
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({
      children: "Error: " + error,
      severity: "error",
    });
    setOpen(false);
  }, []);

  const renderAutocompleteVendor = React.useCallback((params) => {
    return (
      <DataGridAutocomplete
        {...params}
        width={columnWidths[params.field]}
        data={vendors}
        setOptions={setVendors}
        labelPath=""
        value={params.value}
        handleAddToData={updateVendors}
      />
    );
  });

  const renderCustomTextInput = React.useCallback((params) => {
    return <CustomTextInput {...params} />;
  });

  const renderCustomNumberInput = React.useCallback((params) => {
    return <CustomNumberInput {...params} />;
  });

  const renderAutocompleteCellSkuItem = React.useCallback(
    (params) => {
      return (
        <DataGridAutocomplete
          {...params}
          value={params.value}
          width={columnWidths[params.field]}
          data={skuList[params.colDef.skuType].items}
          handleAddToData={launchAddSkuModal}
          typePublicId={skuList[params.colDef.skuType].publicId}
          field={params.colDef.field}
          labelPath="label"
        />
      );
    },
    [columnWidths, setModalOpen]
  );

  const renderAutocompleteCellSkuItemPropogateChecklistSearch =
    React.useCallback(
      (params) => {
        return (
          <DataGridAutocomplete
            {...params}
            value={params.value}
            width={columnWidths[params.field]}
            data={skuList[params.colDef.skuType].items}
            handleAddToData={launchAddSkuModal}
            typePublicId={skuList[params.colDef.skuType].publicId}
            field={params.colDef.field}
            labelPath="label"
            propogateOnBlur={(row) => {
              recalculateFilter(row);
            }}
          />
        );
      },
      [columnWidths, setModalOpen]
    );

  const renderAutocompleteCellSetVariety = React.useCallback(
    (params, apiRef) => {
      const data = skuList["Set"].items.map((item) => {
        return { ...item, origin: "Database" };
      });
      const checklistDataTemp = getChecklistData(params.row.id);
      const checklistData = checklistDataTemp.map((item) => {
        return { ...item, origin: "Checklist" };
      });
      const combinedData = data.concat(checklistData);
      return (
        <DataGridAutocomplete
          {...params}
          value={params.value}
          width={columnWidths[params.field]}
          data={combinedData}
          handleAddToData={launchAddSkuModal}
          typePublicId={skuList[params.colDef.skuType].publicId}
          field={params.colDef.field}
          labelPath="label"
          groupPath="origin"
          isGrouped
          propogateOnBlur={(row) => {
            const player = row.player?.label;
            const year = row.year?.label;
            const manufacturer = row.manufacturer?.label;
            const set = row.setVariety?.label;
            const series = row.series?.label;

            if (player && year && manufacturer && set && series) {
              findTeams(
                player,
                year,
                manufacturer,
                series,
                set,
                apiRef,
                params.row.id,
                "teams"
              );
            }
          }}
        />
      );
    },
    [columnWidths, setModalOpen, recalculateFilter]
  );

  const renderCustomDate = React.useCallback((params) => {
    return <CustomDateInput {...params} setSnackbar={setSnackbar} />;
  });
  const renderCustomUser = React.useCallback((params) => {
    return (
      <DataGridAutocomplete
        {...params}
        data={users}
        value={params.value}
        width={columnWidths[params.field]}
        labelPath=""
      />
    );
  });

  const renderMultiSelectAutoCompleteCell = React.useCallback(
    (params, apiRef) => {
      return (
        <DataGridAutocomplete
          data={teams}
          isMultiselect
          apiRef={apiRef}
          id={params.id}
          field={params.field}
          value={params.row[params.field]}
          labelPath="name"
          restrictInput
        />
      );
    },
    [teams]
  );

  const renderCert = (params) => {
    const label = params.value ? params.value : "";
    const isPSA = params.row.grade.label.includes("PSA");
    const certClick = () => {
      window.open(
        "https://www.psacard.com/cert/" + label,
        "_blank",
        "noopener,noreferrer"
      );
    };
    return (
      <Typography
        onClick={isPSA ? certClick : null}
        style={{ fontSize: "14px" }}
      >
        {label}
      </Typography>
    );
  };

  const renderUser = (params) => {
    const label = params.value ? params.value : "";
    return <Typography style={{ fontSize: "14px" }}>{label}</Typography>;
  };

  const renderACDisplayCell = (params) => {
    const label = params.value ? params.value.label : "";
    const width = columnWidths[params.field];

    return (
      <Typography style={{ fontSize: "14px", width: width }}>
        {label}
      </Typography>
    );
  };

  const renderMultiselectACDisplayCell = (params) => {
    const label = params.value
      ? params.value.map((obj) => obj.name).join("/")
      : "";
    const width = columnWidths[params.field];

    return (
      <Typography style={{ fontSize: "14px", width: width }}>
        {label}
      </Typography>
    );
  };

  const renderSelectCell = React.useCallback(
    (params) => {
      return (
        <StatusSelect
          width={columnWidths[params.field]}
          style={{ fontSize: "14px" }}
          {...params}
          statusTypes={statusTypes}
          onKeyDown={(event) => {
            if (event.key === "Tab") {
              event.target.blur();
            }
          }}
        />
      );
    },
    [statusTypes]
  );

  const renderAttributesSelectCell = React.useCallback((params) => {
    const options = [
      { value: "RC", label: "RC" },
      { value: "Auto", label: "Auto" },
      { value: "Auto RC", label: "Auto RC" },
      { value: "1st", label: "1st" },
      { value: "1st Auto", label: "1st Auto" },
      { value: "", label: "Empty" },
    ];

    return (
      <AutocompleteAttributes
        style={{ fontSize: "14px" }}
        {...params}
        options={options}
      />
    );
  }, []);

  const renderSelectDisplayCell = (params) => {
    const label = params.value.label ? params.value.label : "";
    return (
      <Typography style={{ fontSize: "14px" }}>{label ? label : ""}</Typography>
    );
  };

  const renderAttributesSelectDisplayCell = (params) => {
    const value = params.value ? params.value : "";
    return (
      <Typography style={{ fontSize: "14px" }}>{value ? value : ""}</Typography>
    );
  };

  function changeColumnWidth(event) {
    const { field: columnId, width: newWidth } = event.colDef;
    setColumnWidths({
      ...columnWidths,
      [columnId]: newWidth,
    });
  }

  const changeStatusOnLiveCells = (refRows, status) => {
    if (filterModel.items.some((item) => item.field === "status")) {
      // This is the case where the items will be removed from the filter model
      setRows(
        rows.filter((row) => {
          return !refRows.includes(row.id);
        })
      );
    } else {
      //This is the case where the items are modified and still rendered
      setRows(
        rows.map((row) => {
          if (refRows.includes(row.id)) {
            return { ...row, status: status };
          }
          return row;
        })
      );
      handleRowSelection(
        selectedRows.map((row) => {
          return { ...row, status: status };
        })
      );
    }
  };

  async function createRecords(type) {
    if (!open) {
      setOpen(true);
      const response = await fetchPromise("/Card/new/" + cardsToAdd, "POST", {
        userId: user["id"],
        body: {
          type: type,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setSnackbar({
          children: "Added " + data.length + " new records",
          severity: "success",
        });
        goTo(type);
      } else {
        setSnackbar({
          children: "Failed to add new records",
          severity: "error",
        });
      }
      setOpen(false);
    }
  }

  function deselect() {
    if (autoDeselect) {
      setSelectedRows([]);
      setSelectionModel([]);
    }
  }

  // Verifies a cards record & creates a new card if card is not found within inventory system.
  async function verifyRecord() {
    if (!open) {
      setOpen(true);
      const response = {};
      if (response.status === 200) {
        setSnackbar({
          children: "Successfully added to dear",
          severity: "success",
        });
        const data = await response.json();
        processRowUpdate(data);
        var newRows = [];
        newRows.push(data);
        setRows(rows.map((row) => (row.id === data.id ? data : row)));
        deselect();
        setOpen(false);
        return data;
      } else {
        setSnackbar({ children: "Failed to add to dear", severity: "error" });
      }
      setOpen(false);
    }
    return null;
  }

  // Creates a new pantheon
  async function createPantheon() {
    if (!open) {
      setOpen(true);
      var data = [];
      selectedRows.forEach((row) => {
        data.push({
          ProductID: row.productId,
          Quantity: 1,
          BatchSN: row.id.toString(),
        });
      });
      await fetchPromise("/Pantheon/new/repack", "POST", {
        body: { selectedRows },
      });

      /*
      if (response.status === 200) {
        const d = await response.json();
        var ourData = {};
        d.forEach((item) => {
          ourData[item.id] = item;
        });
        setRows(rows.map((row) => (ourData[row.id] ? ourData[row.id] : row)));
        deselect();
        setSnackbar({
          children: "Successfully created a new pantheon assembly in dear",
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "Failed to create a pantheon",
          severity: "error",
        });
      }
      setOpen(false);
      */
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var updatePagination = null;

  function setJumpPage(updateFunction) {
    updatePagination = updateFunction;
  }

  const renderAutoCompleteCellPlayer = useCallback(
    (params) => {
      return (
        <DataGridAutocomplete
          {...params}
          value={params.value}
          width={columnWidths[params.field]}
          data={players}
          handleAddToData={launchAddPlayerModal}
          labelPath="label"
          propogateOnBlur={(row) => {
            recalculateFilter(row);
          }}
        />
      );
    },
    [columnWidths, players, launchAddPlayerModal]
  );

  if (!isMountedColumnWidths || !isMountedColOrder) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      className="data-grid"
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
          fontSize: 5,
        },
      }}
    >
      <CardActionsInputGroup
        cardsToAdd={cardsToAdd}
        setCards={setCards}
        createRecords={createRecords}
        verifyRecord={verifyRecord}
        setOpen={setOpen}
        fetchPromise={fetchPromise}
        selectedRows={selectedRows}
        open={open}
        setSnackbar={setSnackbar}
        rows={rows}
        setRows={setRows}
        deselect={deselect}
        setSelectedRows={handleRowSelection}
        createPantheon={createPantheon}
        repackOn={repackOn}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <DataGridComponent
        rows={rows}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        changeColumnWidth={changeColumnWidth}
        columnFilterDisplayModel={columnFilterDisplayModel}
        handleRowEditStart={handleRowEditStart}
        handleRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        handleProcessRowUpdateError={handleProcessRowUpdateError}
        rowSelectionModel={selectionModel}
        setSelectedRows={setSelectedRows}
        setSelectionModel={setSelectionModel}
        handleRowModesModelChange={handleRowModesModelChange}
        selectedRows={selectedRows}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRows);
          setSelectionModel(ids);
        }}
        rowModesModel={rowModesModel}
        setColumnVisibility={setColumnVisibility}
        refresh={refresh}
        setRefresh={setRefresh}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        columnWidths={columnWidths}
        colOrder={colOrder}
        setColOrder={setColOrder}
        handleSaveClick={handleSaveClick}
        handleMoveGradeClick={handleMoveGradeClick}
        handleMoveRawClick={handleMoveRawClick}
        handleCancelClick={handleCancelClick}
        handleEditClick={handleEditClick}
        renderAutoCompleteCellPlayer={renderAutoCompleteCellPlayer}
        renderMultiSelectAutoCompleteCell={renderMultiSelectAutoCompleteCell}
        renderACDisplayCell={renderACDisplayCell}
        renderAutocompleteCellSkuItem={renderAutocompleteCellSkuItem}
        renderAttributesSelectCell={renderAttributesSelectCell}
        renderAttributesSelectDisplayCell={renderAttributesSelectDisplayCell}
        renderSelectCell={renderSelectCell}
        renderSelectDisplayCell={renderSelectDisplayCell}
        renderCellExpand={renderCellExpand}
        renderCustomTextInput={renderCustomTextInput}
        renderAutocompleteVendor={renderAutocompleteVendor}
        renderCustomDate={renderCustomDate}
        renderAutoCompleteUser={renderCustomUser}
        renderUser={renderUser}
        renderCert={renderCert}
        setJumpPage={setJumpPage}
        renderCustomNumberInput={renderCustomNumberInput}
        teams={teams}
        statusTypes={statusTypes}
        MoveCardConfirmationModal={MoveCardConfirmationModal}
        headCard={headCard}
        changeStatusOnLiveCells={changeStatusOnLiveCells}
        renderMultiselectACDisplayCell={renderMultiselectACDisplayCell}
        getRowHeight={getRowHeight}
        renderAutocompleteCellSetVariety={renderAutocompleteCellSetVariety}
        renderAutocompleteCellSkuItemPropogateChecklistSearch={
          renderAutocompleteCellSkuItemPropogateChecklistSearch
        }
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={10000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SkuCorrectionProvider
        open={openSkuCorrection}
        setOpen={setOpenSkuCorrection}
        datafields={skuCorrectionData}
        setDatafields={setSkuCorrectionData}
        updateSku={updateSkus}
        url={urlToSend}
        body={bodyToSend}
      >
        <SkuCorrectionModal />
      </SkuCorrectionProvider>
      <ButtonGroup>
        <UploadChecklistButton />
        <ViewChecklistButton />
      </ButtonGroup>
    </Box>
  );
}

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string,
};
