import React, { useCallback, useEffect, useState } from "react";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import SeriesInfoPanel from "./SeriesInfoPanel";
import TicketsTable from "./TicketView";
import RepackStorePreview from "./RepackStorePreview";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import StagedRepackPreview from "./StagedRepackPreview";
import { RepackBoxType } from "../Types/RepackBoxType";
import StagedCasesPreview from "./StagedCasesPreview";
import { useRepackCreatorCard } from "../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { RepackSeries } from "../Types/RepackSeries";

export default function RepackCreationContent() {
  const {
    boxTypes,
    selectedType,
    setSelectedType,
    id,
    setId,
    seriesList,
    ticketTypes
  } = useBuildRepacks();
  const {rows} = useRepackCreatorCard();

  const [series, setSeries] = useState<Pick<RepackSeries, 'id' | 'productId'> | null>(null);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromUrl = urlParams.get("id");
    setId(idFromUrl);
  }, [setId]);

  useEffect(() => {
    if (seriesList?.length > 0 && id) {
      const foundSeries = seriesList.find((s) => s.publicId === id);
      if (foundSeries) {
        setSeries({
          id: foundSeries.id,
          productId: foundSeries.productId
        });
      } else {
        setSeries(null);
      }
    }
  }, [seriesList, id]);

  const TicketView = useCallback(() => {
    return <TicketsTable />;
  }, [rows]);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: theme.palette.background.default,
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: "bold",
            paddingLeft: "2%",
          }}
        >
          Edit {series?.productId || "Repack"}
        </Typography>

        <Box
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {/* Series Info */}
          <SeriesInfoPanel
            boxTypes={boxTypes as RepackBoxType[]}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            boxes={[]}
            ticketTypes={ticketTypes}
          />
        </Box>
      </Box>

      <Box
        className="repack-container"
        sx={{
          borderRadius: 1,
          height: "calc(100% - 80px)",
          overflow: "auto",
        }}
      >
        <Grid
          container
          spacing={1}
          direction={isXSmallScreen ? "column" : "row"}
        >
          {/* Left side - Card Selection */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <RepackStorePreview />
              <TicketView />
            </Box>
          </Grid>

          {/* Right side - Repack Configuration */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              {/* Staged Repacks */}
              <StagedRepackPreview />
              <StagedCasesPreview />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
