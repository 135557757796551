import { OperatorSetBuilder } from "../../../Components/CommonUtility/ServerSide/FilterComponents/OperatorsBuilder";


interface FilterProps {
  options: any[];
  field: any;
  updateFilterModel: (field:string,operator:string,value:any) => any;
  filterModel: any;
}

export const GradeFilter = ({ options }: FilterProps) => {
  const operatorBuilder = new OperatorSetBuilder();
  operatorBuilder.addAutocompleteSingleValue("is", "=", options, "label");
  operatorBuilder.addAutocompleteSingleValue("is not", "!=", options, "label");
  operatorBuilder.addAutocompleteSingleValue("greater than", ">", options, "label");
  operatorBuilder.addAutocompleteSingleValue("less than", "<", options, "label");
  operatorBuilder.addAutocompleteSingleValue("greater than or equal to", ">=", options, "label");
  operatorBuilder.addAutocompleteSingleValue("less than or equal to", "<=", options, "label");
  operatorBuilder.addBasicOperator("contains", "contains");
  operatorBuilder.addBasicOperator("does not contain","notContains")
  operatorBuilder.addNoValueFilter("is Raw", "isRaw");
  operatorBuilder.addNoValueFilter("is graded", "isGraded");
  operatorBuilder.addAutocompleteMultipleValue("is any of", "isAnyOf", options, "label");
  return operatorBuilder.build();
}