import { Box } from "@mui/material";
import RepackTicketManager from "../Dash/RepackPage/RepackTicketManager/RepackTicketManager";
import { BuildRepacksProvider } from "../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackTicketProvider } from "../Contexts/RepackContexts/RepackTicketContexts/RepackTicketContext";
import { CardsProvider } from "../Contexts/CardsContext/CardsContext";
import { RepackCreatorCardPreviewProvider } from "../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";

const TicketPage = () => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      padding: "5px",
    }}
  >
    <CardsProvider>
      <RepackCreatorCardPreviewProvider>
        <BuildRepacksProvider>
          <RepackTicketProvider>
            <RepackTicketManager />
          </RepackTicketProvider>
        </BuildRepacksProvider>
      </RepackCreatorCardPreviewProvider>
    </CardsProvider>
  </Box>
);

export default TicketPage;
