import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import {  Button } from "reactstrap";
import SimpleFileUploader from "../../../../Components/FileUploader/SimpleFileUploader";
import { useFileUploader } from "../../../../Components/FileUploader/FileUploaderContext";
import { useEffect, useState } from "react";
import FetchModule from "../../../../Modules/FetchModule";
const fetchData = new FetchModule();
export default function UploadChecklistModal(props:any){

  const {open, setOpen} = props;
  const { selectedFiles,uploadToBackend,uploading } = useFileUploader();
  const [alreadyUploaded, setAlreadyUploaded] = useState<boolean>(false);
  const [verifying,setVerifying] = useState<boolean>(false);
  const handleSubmit = async () => {
    

    await uploadToBackend();

    
    handleClose();
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkIfFileExists = async ()=>{
    const name = selectedFiles.getAll('file')[0].name;
    const result = await fetchData.fetchResponseAuthed("/Checklist/exists/"+name, "GET");
    if(!result.ok){
      const data = await result.json();
      setAlreadyUploaded(data);
      setVerifying(false);
    }
  }

  useEffect(() => {
    if(selectedFiles?.getAll('file')?.length > 0){
      setAlreadyUploaded(true);
      setVerifying(true);
      checkIfFileExists()
    }
  }, [selectedFiles]);

  return (
   
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bulk Upload Checklist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure file content is of format that matches Panini's
            checklist: sport, year, brand, program, cardset, athlete, team,
            position, card#, sequence.
          </DialogContentText>
          {
            alreadyUploaded && !verifying &&( 
            <Typography color="error">
              File has already been uploaded
            </Typography>) 
          }
          {
            verifying && <Typography color = "warning">Verifying if the file has already been uploaded...</Typography>
          }          
          <SimpleFileUploader 
              supportsText="Supports: .csv" 
              subText="Panini Checklist Format: sport, year, brand, program, cardset, athlete, team, position, card#, sequence" 
              uploadHandledExternally={true}/>
{
         uploading &&( 
            <Typography color="info">
              The checklist will update in the background, this can take a few minutes.
            </Typography>) 
          }
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>
          {uploading ? 'Close' : 'Cancel'}
        </Button>
          <Button onClick={handleSubmit} disabled={!(selectedFiles && !uploading && !alreadyUploaded)}>Submit</Button>
        </DialogActions>
      </Dialog>
      
   
  );
}