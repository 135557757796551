import * as React from "react";
import { createContext, MutableRefObject, useContext } from "react";
import {
  GridApiPro,
  GridFilterModel,
  GridSortModel,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";
import { useDatagridDataConnection } from "../../../Components/CommonUtility/ServerSide/useDatagridDataConnection";

type CardsProviderProps = {
  children?: React.ReactNode;
};

interface DataGridConnectionConfig {
  filterKey: LocalStorageKey;
  sortKey: LocalStorageKey;
  automaticallyRefreshData: boolean;
  isPaginated: boolean;
  baseurl: string;
  goToURL: string;
}

interface RepackCreatorCardPreviewContextProps {
  refreshData: any;
  rows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  isGettingData: boolean;
  totalRows: number;
  goTo: (identifier: number | string) => Promise<void>;
  goToViaURLParams: any;
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  getPreprocessedFilterModel: () => GridFilterModel;
  updateFilterModel: (field: string, operator: string, value: any) => void;
  paginationModel: GridPaginationModel;
  setCurrentPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  lastEditWasManual: boolean;
  setLastEditWasManual: (value: boolean) => void;
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  resetSortModel: () => void;
  setJumpToRef: any;
  gridApi: MutableRefObject<GridApiPro | GridApiPremium>;
}

const RepackCreatorCardPreviewContext =
  createContext<RepackCreatorCardPreviewContextProps | null>(null);

export const RepackCreatorCardPreviewProvider = ({
  children,
}: CardsProviderProps) => {
  const config: DataGridConnectionConfig = {
    filterKey: LocalStorageKey.CARD_DASHBOARD_FILTERS,
    sortKey: LocalStorageKey.CARD_DASHBOARD_SORT,
    automaticallyRefreshData: true,
    isPaginated: false,
    baseurl: "/Card/all/available",
    goToURL: "/Card/getlastcardpage",
  };

  const {
    refreshData,
    rows,
    setRows,
    isGettingData,
    totalRows,
    goTo,
    goToViaURLParams,
    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel,
    paginationModel,
    setCurrentPage,
    setPageSize,
    lastEditWasManual,
    setLastEditWasManual,
    sortModel,
    setSortModel,
    resetSortModel,
    setJumpToRef,
    gridApi,
  } = useDatagridDataConnection(config);

  const contextValues: RepackCreatorCardPreviewContextProps = {
    refreshData,
    rows,
    setRows,
    isGettingData,
    totalRows,
    goTo,
    goToViaURLParams,
    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel,
    paginationModel,
    setCurrentPage,
    setPageSize,
    lastEditWasManual,
    setLastEditWasManual,
    sortModel,
    setSortModel,
    resetSortModel,
    setJumpToRef,
    gridApi,
  };

  return (
    <RepackCreatorCardPreviewContext.Provider value={contextValues}>
      {children}
    </RepackCreatorCardPreviewContext.Provider>
  );
};

export function useRepackCreatorCard(): RepackCreatorCardPreviewContextProps {
  const context = useContext(RepackCreatorCardPreviewContext);
  if (!context) {
    throw new Error(
      "Invalid RepackCreatorCardPreviewContext, must be wrapped in RepackCreatorCardPreviewProvider"
    );
  }
  return context;
}
